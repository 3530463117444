import React from "react";
import { LANGUAGE } from "./App";
import { FaqNl } from "./components/FaqNl";
import { FaqFr } from "./components/FaqFr";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { isNL } from "./Utils";

export function Faq({ language }: { language: string }) {
  const { t } = useTranslation("home");


  return (
    <div className="containerTransparant">
      <Helmet>
        <title>{!isNL() ? t("faq.documentTitle") : "Veel gestelde vragen | Banden-Online-Kopen.nl!"}</title>
        <meta name="title" content={t("faq.documentTitle")} />
        <meta name="description" content={t("faq.meta")}/>
        </Helmet>
      {language === "nl" ? <FaqNl /> : <FaqFr />}
    </div>
  );
}
