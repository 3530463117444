import React from "react";
import zomerband from "../img/services/zomerband-profiel.jpeg";
import winterband from "../img/services/winterband-profiel.jpeg";
import allseasonband from "../img/services/all-seasonband-profiel.jpeg";
import { Link } from "react-router-dom";

export function ServicesBanner() {
  const items = [
    {
      image: zomerband,
      description: "Zomerband profiel",
    },
    {
      image: winterband,
      description: "Winterband profiel",
    },
    {
      image: allseasonband,
      description: "All-seasonband profiel",
    },
  ];

  const services = items.map((service, index) => (
    <div className="profileItem">
      <img src={service.image} alt={service.description} />
      <p className="boldGreen">{service.description}</p>
    </div>
  ));

  return (
    <>
      <div className="containerGreen">
        <h1 className="white">Hoe herken ik mijn soort van autoband?</h1>
        <div className="flex wrap justify-center">{services}</div>
      </div>
    </>
  );
}
