import React from "react";
import { Link } from "react-router-dom";
import { isNL } from "../Utils";

export function FaqNl() {
  return (
    <>
      <h1>Veel gestelde vragen</h1>
      <p>
        {!isNL() ? "Banden-Online-Kopen.be!" : "Banden-Online-Kopen.nl"} wil je zo goed mogelijk begeleiden in de aankoop
        van de banden die geschikt zijn voor jouw wagen en jouw situatie. <br />
        We bundelden hieronder meest gestelde vragen van onze klanten. <br />
        Vind je daar het antwoord niet dan zijn wij er om je bij te staan.
      </p>

      <h2>Vragen over de bestelling</h2>
      <h3>Hoe bestel ik mijn banden?</h3>
      <p>
        Volg deze stappen:
        <ol>
          <li>
            <strong>Lees de bandenmaat af</strong> van de huidige banden. <br />
            Onze{" "}
            <Link to="/tyre-guide" className="link">
              bandengids
            </Link>{" "}
            assisteert je hierbij.
          </li>
          <li>
            Gebruik de uitgebreide <strong>zoekfunctie</strong> op de{" "}
            <Link to="/" className="link">
              homepagina
            </Link>
            .
          </li>
          <li>
            <strong>Kies de ideale band</strong> die aan al je voorkeuren
            voldoet. <br />
            Gebruik hiervoor de <strong>filterfunctie</strong>.
          </li>
          <li>Kies het aantal en de aflevermethode en bestel.</li>
        </ol>
      </p>
      <h3>Kan ik mijn afleveradres nog aanpassen na mijn bestelling?</h3>
      <p>
        Alle orders worden bij Banden-Online-Kopen.be! volledig automatisch
        aangemaakt. <br />
        Onze transportpartners komen meerdere keren per dag de bestellingen
        ophalen.
        <br />
        Daardoor is het niet mogelijk om het afleveradres nog aan te passen na
        de bestelling.
        <br />
      </p>
      <h3>Kan ik alleen via internet bestellen?</h3>
      <p>
        {!isNL() ? "Banden-Online-Kopen.be!" : "Banden-Online-Kopen.nl"} biedt zijn producten{" "}
        <strong>enkel online</strong> aan.
        <br />
        Bestellen kan dus van <strong>overal</strong>.<br />
        Je betaalt rechtstreeks <strong>via de webshop</strong> en deze methode
        is <strong>veilig</strong>.<br />
        Enkel zo kunnen wij onze kosten laag houden en kunnen wij jou de{" "}
        <strong>beste prijzen</strong> aanbieden.
        <br />
      </p>
      <h3>
        Betaal ik nog andere kosten bovenop de prijs bij de producten vermeld?
      </h3>
      <p>
        Onze prijzen zijn altijd <strong>inclusief alle kosten</strong>.<br />
        Geen verrassingen nadien.
      </p>
      <h3>Waar kan ik terecht met eventuele vragen over mijn bestelling?</h3>
      <p>
        Je kan het {!isNL() ? "Banden-Online-Kopen.be!" : "Banden-Online-Kopen.nl"}-team bereiken via het{" "}
        <Link to="/contact" className="link">
          contactformulier
        </Link>{" "}
        of via{" "}
        <a href={!isNL() ? "mailto:info@banden-online-kopen.be" : "mailto:info@banden-online-kopen.nl"} className="link">
          mail
        </a>
        .<br />
        Noteer bij elke communicatie je <strong>bestelnummer</strong>.
      </p>
      <h3>Ik heb mijn bestelling geplaatst, wat nu?</h3>
      <p>
        Je ontvangt een mail met het overzicht van je bestelling.
        <br />
        Binnen max. 3 werkdagen worden de banden bij jou thuis of bij de gekozen
        montagepartner geleverd.
        <br />
        Uiteraard blijven wij beschikbaar via{" "}
        <a href={!isNL() ? "mailto:info@banden-online-kopen.be" : "mailto:info@banden-online-kopen.nl"} className="link">
          mail
        </a>{" "}
        of het{" "}
        <Link to="/contact" className="link">
          contactformulier
        </Link>
        .<br />
        Houd altijd je <strong>bestelnummer</strong> bij de hand.
      </p>
      <h3>Wat is de levertijd van mijn bestelling?</h3>
      <p>
        Wij werken met verschillende koerierdiensten, daarom verschilt de
        levertijd. Ons doel is om je bestelling binnen de 3 werkdagen te
        leveren.
      </p>

      <h2>Vragen over de montage</h2>
      <h3>Waar kan ik de banden laten monteren?</h3>
      <p>
        Je kan dit zelf doen of je kan voor een montagepartner in de buurt
        kiezen. Een overzicht vind je{" "}
        <Link to="/partners" className="link">
          hier
        </Link>
        . <br />
        Je duidt jouw gekozen monteur aan in de bestelprocedure.
      </p>
      <h3>
        Hoe maak ik een afspraak voor de montage van mijn bestelde banden?
      </h3>
      <p>
        De gekozen montagepartner ontvangt een detail van je bestelling en de
        voorziene levering. <br />
        Wil je de banden daar laten monteren?
        <br />
        <strong>
          Neem dan contact op en regel zelf jouw afspraak voor montage.
        </strong>
        <br />
      </p>
      <h3>
        Wie is er verantwoordelijk voor de controle van de afgeleverde
        bestelling?
      </h3>
      <p>
        Jij staat altijd in voor de controle van de levering, ook al gebeurt die
        bij onze montagepartner.
        <br />
        De controle gebeurt altijd vóór de montage.
        <br />
        Gemonteerde banden worden niet meer teruggenomen.
        <br />
        Het terugroepingsrecht is dan niet meer van toepassing.
      </p>

      <h2>Vragen over onze voorraad</h2>
      <h3>Zijn alle producten voorradig op de site?</h3>
      <p>
        Ja, onze volledige voorraad staat weergegeven op onze website. Bij elk
        specifiek product staat vanaf 8 stuks het resterende aantal vermeld.
      </p>
      <h3>Wat als ik mijn gewenste band niet vind?</h3>
      <p>
        Staat de band die je wil niet in onze webshop, dan hebben we die ook
        niet in voorraad.
        <br />
        Van zodra die banden terug op stock zijn, passen we de website aan.
        <br />
        Contacteer gerust het {!isNL() ? "Banden-Online-Kopen.be!" : "Banden-Online-Kopen.nl"}-team met vragen over een
        product dat niet op stock is.
        <br />
        Wij helpen je bij de zoektocht naar een waardig alternatief.
      </p>
    </>
  );
}
