import React from "react";
import { getDecodedToken, Pricing } from "./api/AuthApi";
import "./Dashboard.css";
import "react-datetime/css/react-datetime.css";
import { Brand } from "./api/BrandApi";
import { PartnerDashboard } from "./PartnerDashboard";
import { tokenIsExpired } from "./Utils";
import { Redirect } from "react-router-dom";
import { AdminOrders } from "./AdminOrders";

export function Dashboard({
  brands,
  pricing,
}: {
  brands: Brand[];
  pricing: Pricing;
}) {
  const token = getDecodedToken();

  if (!token || tokenIsExpired(token)) {
    return <Redirect push to={"/partner-login"} />;
  }

  if (token.admin) {
    return <AdminOrders brands={brands} />;
  }

  return (
    <div className="dashboard">
      <PartnerDashboard />
    </div>
  );
}
