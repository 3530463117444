import React from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { isNL } from "./Utils";
import { getAlternateCanonical } from "./AllSeasonTyres";

export function DeliveryOptions() {
  const { t } = useTranslation("delivery-options");

  return (
    <div className="containerTransparant w-50">
      <Helmet>
        {getAlternateCanonical()}
        <link rel="canonical" href={window.location.href} />
        <title>{t("documentTitle") + !isNL() ? ".be!" : ".nl!"}</title>
        <meta name="description" content={t("meta")} />
        <meta name="keywords" content={t("keywords")} />
      </Helmet>
      <h1 className="green">{t("title")}</h1>
      <div
        dangerouslySetInnerHTML={{
          __html: t("delivery-options"),
        }}
      ></div>
    </div>
  );
}
