import React from "react";
import { Brands } from "./components/Brands";
import { Services } from "./components/Services";
import { ToWatch } from "./components/ToWatch";
import { SearchBar } from "./components/SearchBar";
import { Brand } from "./api/BrandApi";
import { Pricing } from "./api/AuthApi";
import { SearchOptions } from "./api/TyreApi";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { isNL, isBE, isFR } from "./Utils";
import { getAlternateCanonical } from "./AllSeasonTyres";

export function Home({
  brands,
  pricing,
  options,
}: {
  brands: Brand[];
  pricing: Pricing;
  options: SearchOptions;
}) {
  const { t } = useTranslation("general");

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        {getAlternateCanonical()}
        <title>
          {!isNL()
            ? t("titles.home")
            : "Autobanden kopen? Banden-Online-Kopen.nl!"}
        </title>
        <meta
          name="description"
          content={
            !isNL()
              ? t("meta.homePage")
              : "Zomer-, all-season of winterbanden online bestellen? Banden-Online-Kopen.nl, dé webshop voor al uw autobanden. Wij bieden een uitgebreid assortiment van topmerken en zorgen voor een snelle levering bij u thuis of op uw gewenste adres in heel Nederland. Bekijk ons aanbod en bestel eenvoudig online!"
          }
        />
      </Helmet>
      <SearchBar brands={brands} options={options} />
      <ToWatch brands={brands} pricing={pricing} />
      <Services />
      <Brands brands={brands} />
    </>
  );
}
