import React, { useState } from "react";
import "./PartnerLogin.css";
import { resetPassword } from "./api/AuthApi";
import { useParams, Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";

export function PasswordReset() {
  // @ts-ignore
  const { token } = useParams();
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [message, setMessage] = useState<string | null>(null);
  const [redirect, setRedirect] = useState(false);
  const { i18n } = useTranslation();

  if (redirect) {
    return <Redirect push to={"/partner-login"} />;
  }

  return (
    <div className="partner-container flex-column flex items-center justify-center">
      <h2>Wachtwoord resetten</h2>
      <form
        onSubmit={async e => {
          e.preventDefault();

          const result = await resetPassword(token, password, i18n.language);
          setMessage(result);
          setRedirect(true);
        }}
      >
        <label>Wachtwoord</label>
        <input
          value={password}
          onChange={e => {
            setPassword(e.target.value);
          }}
          className="input"
          type="password"
          placeholder="Wachtwoord"
          required={true}
        />
        <label>Herhaal achtwoord</label>
        <input
          value={passwordConfirm}
          onChange={e => {
            setPasswordConfirm(e.target.value);
          }}
          className="input"
          type="password"
          placeholder="Wachtwoord"
          required={true}
        />
        {message ? <div className="mb-4">{message}</div> : null}
        <button className="button w-fit" type="submit">
          Reset
        </button>
      </form>
    </div>
  );
}
