import React from "react";
import { useTranslation } from "react-i18next";

export function Returns() {
  const { t } = useTranslation("returns");

  return (
    <div className="containerTransparant w-45">
      <h1 className="green">{t("title")}</h1>
      <h2>{t("subtitle")}</h2>
      <div dangerouslySetInnerHTML={{ __html: t("description") }}></div>
    </div>
  );
}
