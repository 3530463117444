import React from "react";
import shoppingCart from "../img/shopping_cart.png";
import sun from "../img/sun.svg";
import carImage from "../img/car.svg";
import "./TyreToWatch.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  getCorrectFuelImage,
  getCorrectKindImage,
  getCorrectNoiseImage,
  getCorrectWetImage,
} from "../Utils";
import { useLocation } from "react-use";
import tyreImg from "../img/tyre.png";

export function TyreToWatch({
  id,
  title,
  model,
  kind,
  price,
  car,
  competitors,
  logos,
  width,
  height,
  radius,
  loadIndex,
  speedIndex,
  wetRoadEfficiency,
  fuelEfficiency,
  brandId,
  noise,
  noiseLevel,
  image,
  topSale,
  promo,
}: {
  id: string;
  title: string;
  model: string;
  kind: string;
  price: number;
  car: string;
  competitors: number[];
  logos: string[];
  width: string;
  height: string;
  radius: number;
  loadIndex: string;
  speedIndex: string;
  wetRoadEfficiency: string;
  fuelEfficiency: string;
  noise: number;
  noiseLevel: string;
  brandId: string;
  image: string | null;
  topSale?: boolean;
  promo?: boolean;
}) {
  const location = useLocation();
  const { t } = useTranslation("home");
  const { t: generalT } = useTranslation("general");

  const otherPrizes = competitors.map((prize, index) => (
    <div key={index} className="price-container">
      {logos[index] !== "" ? (
        <img className="price-logo" src={logos[index]} alt={"Logo"} />
      ) : null}
      <div className="price">€{prize}</div>
    </div>
  ));
  return (
    <div className="tyreToWatch fade-in flex flex-column">
      <Link
        to={`/product/${model
          .toLowerCase()
          .trim()
          .replaceAll(" ", "-")}-${title
          .toLowerCase()
          .trim()
          .replaceAll(
            " ",
            "-"
          )}-${width}-${height}-r${radius}-${loadIndex}${speedIndex}-${id}${
          location.search
        }`}
      >
        <img src={image ? image : tyreImg} alt="Voorbeeld band" />
        {topSale ? (
          <div className="ribbon-wrapper">
            <div className="ribbon">Topverkoop!</div>
          </div>
        ) : null}
        {promo ? (
          <div className="ribbon-wrapper">
            <div className="ribbon green">Promo</div>
          </div>
        ) : null}
      </Link>
      <div className="infoTyre">
        <div className="flex items-center wrap">
          <div className="title mr-auto">{model}</div>
          <div>
            {width}/{height} R{radius} {loadIndex} {speedIndex}
          </div>
        </div>
        <div className="subtitle">{title}</div>
        <div className="kind">
          <img src={getCorrectKindImage(kind)} alt="Zomerband" />
          <div>{generalT(kind)}</div>
        </div>
        <div className="kind">
          <img src={carImage} alt="Personenwagen" />
          <div>{generalT(car)}</div>
        </div>
        <div className="kind icons flex items-center">
          <img
            className="efficiency"
            src={getCorrectFuelImage(fuelEfficiency)}
            alt={"fuel"}
          />
          <img
            className="efficiency margin"
            src={getCorrectWetImage(wetRoadEfficiency)}
            alt={"road"}
          />
          <div
            style={{
              marginLeft: "10px",
            }}
          >
            {noise} db
          </div>
          <img
            className="efficiency margin noise"
            src={getCorrectNoiseImage(noiseLevel)}
            alt={"road"}
          />
        </div>
        {competitors.length > 0 ? (
          <div className="competitors">{otherPrizes}</div>
        ) : null}
      </div>
      <div className="mt-auto mb-4 flex justify-flex-end items-center add-button wrap-reverse">
        <Link
          to={`/product/${title
            .toLowerCase()
            .trim()
            .replaceAll(" ", "-")}-${model
            .toLowerCase()
            .trim()
            .replaceAll(
              " ",
              "-"
            )}-${width}-${height}-r${radius}-${loadIndex}${speedIndex}-${id}${
            location.search
          }`}
          className="orderNow flex ml-3 mr-auto"
        >
          <div className="order-text">{t("order_now")} </div>
          <img className="shoppingCart" src={shoppingCart} alt="Winkelmand" />
        </Link>
        <div className="price mr-4">€{price}</div>
      </div>
    </div>
  );
}
