import React, { useState } from "react";
import logo from "../img/LogoBandenOnlineKopen.png";
import logoFr from "../img/LogoFr.png";
import logoNl from "../img/LogoBandenOnlineKopenNLWitteTekst.png";
import shoppingCart from "../img/shopping-cart.png";
import { Link, NavLink } from "react-router-dom";
import "./Header.css";
import { LANGUAGE } from "../App";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons/faBars";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { isBE, isFR, isNL, isStaging } from "../Utils";
import Be from "../img/be.png";
import Nl from "../img/nl.png";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons/faChevronUp";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown";

export function Header({
  language,
  setLanguage,
  cart,
}: {
  language: string;
  setLanguage: (lang: LANGUAGE) => void;
  cart: {
    id: string;
    name: string;
    count: number;
    price: number;
  }[];
}) {
  const [showMobile, setShowMobile] = useState(false);
  const { t, i18n } = useTranslation("header");
  const [openLanguage, setOpenLanguage] = useState(false);

  async function changeLanguage() {
    if (language === LANGUAGE.NL) {
      setLanguage(LANGUAGE.FR);
      await i18n.changeLanguage(LANGUAGE.FR);
    } else {
      setLanguage(LANGUAGE.NL);
      await i18n.changeLanguage(LANGUAGE.NL);
    }
  }

  return (
    <>
      <div className="greenHeader flex items-center justify-flex-end">
        <NavLink
          className="link"
          activeClassName="active"
          to="/faq"
          onClick={() => {
            setShowMobile(false);
          }}
        >
          {t("faq")}
        </NavLink>
        <a
          className="link"
          href={
            isBE()
              ? "https://g.page/r/CRGrgM4zYJPQEBI/review"
              : "https://g.page/r/CehPHrL7jb2KEBI/review"
          }
          rel="noopener noreferrer"
          target="_blank"
          onClick={() => {
            setShowMobile(false);
          }}
        >
          {t("reviews")}
        </a>
        <NavLink
          className="link"
          activeClassName="active"
          to="/partner-login"
          onClick={() => {
            setShowMobile(false);
          }}
        >
          {t("become_partner")}
        </NavLink>
        <div>
          <div
            className="language"
            onClick={() => {
              setOpenLanguage(!openLanguage);
            }}
          >
            {openLanguage ? (
              <FontAwesomeIcon icon={faChevronUp} />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} />
            )}
            {isNL() ? <img className="country" src={Nl} alt="nl" /> : null}
            {isBE() || isFR() ? (
              <img className="country" src={Be} alt="be" />
            ) : null}
          </div>
          <div
            className="flex flex-column lang-box"
            style={{ display: `${openLanguage ? "flex" : "none"}` }}
          >
            <a
              href={`https://${
                isStaging() ? "staging." : ""
              }banden-online-kopen.be${window.location.pathname}${
                window.location.search
              }`}
              hrefLang="nl-be"
              className="flex items-center mb-4 pointer"
            >
              <div className="mr-auto lang-link">België (NL)</div>
              <img className="country ml-5" src={Be} alt="be" />
            </a>
            {
              <a
                href={`https://${
                  isStaging() ? "staging." : ""
                }pneus-enligne.be${window.location.pathname}${
                  window.location.search
                }`}
                hrefLang="fr-be"
                className="flex items-center mb-4 pointer"
              >
                <div className="mr-auto lang-link">Belgique (FR)</div>
                <img className="country ml-5" src={Be} alt="be" />
              </a>
            }
            {
              <a
                href={`https://${
                  isStaging() ? "staging." : ""
                }banden-online-kopen.nl${window.location.pathname}${
                  window.location.search
                }`}
                hrefLang="nl-nl"
                className="flex items-center pointer"
              >
                <div className="mr-auto lang-link">Nederland (NL)</div>
                <img className="country ml-5" src={Nl} alt="be" />
              </a>
            }
          </div>
        </div>
      </div>
      <div className="blackHeader flex items-center justify-flex-end">
        <Link to="/">
          {language === LANGUAGE.FR ? (
            <img src={logoFr} alt="Logo" />
          ) : isNL() ? (
            <img src={logoNl} alt="Logo" />
          ) : (
            <img src={logo} alt="Logo" />
          )}
        </Link>
        <div className="blackHeaderLinks">
          <div className={`navitems ${showMobile ? "mobile" : ""}`}>
            <NavLink
              className="navitem"
              exact={true}
              activeClassName="active"
              to="/"
              onClick={() => {
                setShowMobile(!showMobile);
              }}
            >
              {t("home")}
            </NavLink>
            <NavLink
              className="navitem"
              activeClassName="active"
              to={isFR() ? "/marques-pneus/" : "/bandenmerken/"}
              onClick={() => {
                setShowMobile(!showMobile);
              }}
            >
              {t("our_brands")}
            </NavLink>
            <NavLink
              className="navitem"
              activeClassName="active"
              to="/partners"
              onClick={() => {
                setShowMobile(!showMobile);
              }}
            >
              {t("montage")}
            </NavLink>
            <NavLink
              className="navitem"
              activeClassName="active"
              to="/tyre-guide"
              onClick={() => {
                setShowMobile(!showMobile);
              }}
            >
              {t("tyre-guide")}
            </NavLink>
            <NavLink
              className="navitem"
              activeClassName="active"
              to="/contact"
              onClick={() => {
                setShowMobile(!showMobile);
              }}
            >
              {t("contact")}
            </NavLink>
          </div>
          <Link
            to="/cart"
            onClick={() => {
              setShowMobile(false);
            }}
          >
            <img src={shoppingCart} alt="Shopping cart" />
            <div className="cart-count">
              {cart.map(item => item.count).reduce((a, b) => a + b, 0)}
            </div>
          </Link>
          <div
            className="hamburger"
            onClick={() => {
              setShowMobile(!showMobile);
            }}
          >
            {showMobile ? (
              <FontAwesomeIcon icon={faTimes} />
            ) : (
              <FontAwesomeIcon icon={faBars} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
