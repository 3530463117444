import React, { useState, useEffect } from "react";
import "./Checkbox.css";

export function Checkbox({
  title,
  value,
  onClick,
}: {
  title: string;
  value: boolean;
  onClick: (clicked: boolean) => void;
}) {
  const [checked, setChecked] = useState(value);

  useEffect(() => {
    onClick(checked);
  }, [checked]);

  useEffect(() => {
    setChecked(value);
  }, [value]);

  return (
    <>
      <label
        className="checkbox-container"
      >
        <div dangerouslySetInnerHTML={{ __html:  title}}></div>
        <input type="checkbox" checked={checked} />
        <span
          className="checkmark"
          onClick={e => {
            e.preventDefault();
            setChecked(!checked);
          }}
        />
      </label>
    </>
  );
}
