import React, { useState } from "react";
import { TyreToWatch } from "./TyreToWatch";
import { Pagination } from "./Pagination";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { Tyre, getTyresToWatch, TyreToWatch as Watch } from "../api/TyreApi";
import { useEffectOnce } from "react-use";
import { Brand } from "../api/BrandApi";
import { Pricing } from "../api/AuthApi";
import { getCorrectPrice, getCountry, isBE } from "../Utils";

function paginate(items: Watch[], pageNumber: number, pageSize: number) {
  const startIndex = (pageNumber - 1) * pageSize;

  return _(items)
    .slice(startIndex)
    .take(pageSize)
    .value();
}

export function ToWatch({
  brands,
  pricing,
}: {
  brands: Brand[];
  pricing: Pricing;
}) {
  const [tyres, setTyres] = useState<Watch[]>([]);
  const { t } = useTranslation("home");

  useEffectOnce(() => {
    getTyresToWatch().then(result => {
      setTyres(result.filter(t => t.country === getCountry()));
    });
  });

  const [currentPage, setCurrentPage] = useState(1);

  const pageSize = isMobile ? 1 : 3;

  const getPageData = () => {
    const paginationData = paginate(tyres, currentPage, pageSize);
    return { totalCount: tyres.length, data: paginationData };
  };

  const { totalCount, data } = getPageData();

  const pageCount = Math.ceil(totalCount / pageSize);
  const nextPage = (increment: number) => {
    if (currentPage + increment <= pageCount && currentPage + increment > 0) {
      setCurrentPage(currentPage + increment);
    } else if (pageCount === currentPage) {
      setCurrentPage(1);
    } else if (increment === -1 && currentPage === 1) {
      setCurrentPage(pageCount);
    }
  };

  if (data.length === 0) {
    return null;
  }

  return (
    <div className="toWatchContainer">
      <h2>{t("tyres_to_watch")}</h2>
      <p
        dangerouslySetInnerHTML={{ __html: t("tyres_to_watch_description") }}
      ></p>
      <div className="tyreToWatchContainer">
        <div className="arrow">
          {pageCount > 1 ? (
            <div className="arrow-left" onClick={() => nextPage(-1)} />
          ) : null}
        </div>

        {data.map((tyre, index) => {
          const brand = brands.find(b => b.id === tyre.brandId);

          return (
            <TyreToWatch
              key={index}
              noise={tyre.noiseLevelDb}
              noiseLevel={tyre.noiseClass}
              id={tyre.id}
              height={tyre.height}
              loadIndex={tyre.loadIndex}
              radius={tyre.radius}
              speedIndex={tyre.speedIndex}
              width={tyre.width}
              title={tyre.name}
              model={brand?.name || ""}
              price={getCorrectPrice(tyre, pricing)}
              car={tyre.type}
              kind={tyre.season}
              competitors={tyre.competitorPrizes}
              logos={tyre.competitorLogos}
              wetRoadEfficiency={tyre.wetRoadEfficiency}
              fuelEfficiency={tyre.fuelEfficiency}
              brandId={tyre.brandId}
              image={tyre.image}
            />
          );
        })}

        <div className="arrow">
          {pageCount > 1 ? (
            <div className="arrow-right" onClick={() => nextPage(1)} />
          ) : null}
        </div>
      </div>
      {isMobile ? null : (
        <Pagination
          itemsCount={totalCount}
          pageSize={pageSize}
          currentPage={currentPage}
          updatePage={setCurrentPage}
        />
      )}
    </div>
  );
}
