import React from "react";
import { useTranslation } from "react-i18next";
import { SearchBarSmall } from "./components/SearchBarSmall";
import { Brand } from "./api/BrandApi";
import { SearchOptions } from "./api/TyreApi";
import { ContactSheet } from "./components/ContactSheet";
import { Helmet } from "react-helmet";
import { isNL } from "./Utils";

export function Contact({
  brands,
  options,
}: {
  brands: Brand[];
  options: SearchOptions;
}) {
  const { t } = useTranslation("home");

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("contact.title")}</title>
      </Helmet>
      <SearchBarSmall brands={brands} options={options} />
      <h1 className="green w-80 m-auto">{t("contact.title")}</h1>
      <div className="flex containerTransparant contact">
        <p dangerouslySetInnerHTML={{
          __html: t("contact.description").replaceAll(
            "{email}",
            isNL() ? "info@banden-online-kopen.nl" : "info@banden-online-kopen.be"
            ) }}></p>
        <ContactSheet />
      </div>
    </>
  );
}
