import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import GoogleMapReact from "google-map-react";
import "./Partners.css";
import { FaMapMarkerAlt } from "@react-icons/all-files/fa/FaMapMarkerAlt";
import { PublicPartner } from "./api/AuthApi";
import { useEffectOnce } from "react-use";
import { getAllPartners } from "./api/PartnerApi";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import _ from "lodash";
import { isBE } from "./Utils";
import { getAlternateCanonical } from "./AllSeasonTyres";

const Marker = ({
  lat,
  lng,
  info,
  setClicked,
  reset,
}: {
  lat: any;
  lng: any;
  info: PublicPartner;
  setClicked: (c: string) => void;
  reset: string;
}) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (reset !== info.id) {
      setShow(false);
    }

    if (!show && reset === info.id) {
      setShow(true);
    }
  }, [reset]);

  return (
    <>
      {show ? (
        <div
          style={{
            backgroundColor: "white",
            position: "absolute",
            padding: "8px",
            width: "130px",
            textAlign: "center",
            zIndex: 1000,
          }}
        >
          <div>
            <b>{info.businessName}</b>
          </div>
          <div>
            {info.street} {info.number}
          </div>
          <div>
            {info.zipcode} {info.city}
          </div>
          <div>Tel. {info.phone}</div>
          <div>
            {Object.values(info.placementPrizes).filter(i => i !== 0).length ===
              0 ||
            _.min(Object.values(info.placementPrizes).filter(i => i !== 0)) ===
              0
              ? null
              : `Montage vanaf: €${_.min(
                  Object.values(info.placementPrizes).filter(i => i !== 0)
                )}`}
          </div>
        </div>
      ) : null}
      <div
        onClick={() => {
          setShow(!show);
          setClicked(info.id);
        }}
        style={{
          color: "white",
          background: "black",
          border: "6px solid #63b401",
          padding: "5px 5px",
          borderRadius: "100%",
          transform: "translate(-50%, -50%)",
        }}
      />
    </>
  );
};

const SearchMarker = ({ lat, lng }: { lat: any; lng: any }) => (
  <div
    style={{
      color: "white",
      background: "white",
      border: "6px solid red",
      padding: "5px 5px",
      borderRadius: "100%",
      transform: "translate(-50%, -50%)",
    }}
  />
);

export function Partners() {
  return <CartView title={true} fullWidth={false} selectPartner={() => {}} />;
}

export function CartView({
  title,
  fullWidth,
  selectPartner,
}: {
  title: boolean;
  fullWidth: boolean;
  selectPartner: (partner: PublicPartner) => void;
}) {
  const [partners, setPartners] = useState<PublicPartner[]>([]);
  const [search, setSearch] = useState("");
  const [searchedLocation, setSearchedLocation] = useState<null | {
    lat: number;
    lon: number;
  }>(null);
  const [selectedPartner, setSelectedPartner] = useState<
    PublicPartner | undefined
  >(undefined);
  const [clicked, setClicked] = useState("");

  useEffectOnce(() => {
    getAllPartners().then(result => {
      setPartners(result);
    });
  });

  function calculateDistance(
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number
  ) {
    const R = 6371e3; // metres
    const a1 = (lat1 * Math.PI) / 180; // φ, λ in radians
    const a2 = (lat2 * Math.PI) / 180;
    const d1 = ((lat2 - lat1) * Math.PI) / 180;
    const d2 = ((lon2 - lon1) * Math.PI) / 180;

    const a =
      Math.sin(d1 / 2) * Math.sin(d1 / 2) +
      Math.cos(a1) * Math.cos(a2) * Math.sin(d2 / 2) * Math.sin(d2 / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return parseFloat(`${(R * c) / 1000}`).toFixed(2);
  }

  async function getCorrectLatLon() {
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.set("format", "json");
    urlSearchParams.set("limit", "1");
    urlSearchParams.set("q", `${search}`);

    const result = await fetch(
      `https://nominatim.openstreetmap.org/search?${urlSearchParams.toString()}`
    ).then(r => r.json());

    setSearchedLocation({
      lat: result[0].lat,
      lon: result[0].lon,
    });
  }

  const { t } = useTranslation("general");

  return (
    <>
      <Helmet>
        {getAlternateCanonical()}
        <title>{t("partners.documentTitle")}</title>
        <meta name="description" content={t("meta.partnersPage")} />
      </Helmet>
      <div
        className={`flex justify-center ${
          fullWidth ? "flex-column" : "partners-container"
        }`}
      >
        {fullWidth ? (
          <form
            onSubmit={async e => {
              e.preventDefault();
              await getCorrectLatLon();
            }}
          >
            <input
              className="input"
              type="text"
              placeholder={t("partners.placeholder")}
              value={search}
              onChange={e => {
                setSearch(e.target.value);
              }}
            />
            <button className="button w-fit mb-4" type={"submit"}>
              {t("partners.btn")}
            </button>
          </form>
        ) : null}
        <div
          className={`${fullWidth ? "w-100 flex" : "items"}`}
          style={{ overflow: "auto" }}
        >
          {title ? (
            <h1 className="partner-header">{t("partners.title")}</h1>
          ) : null}

          <div style={{ maxHeight: "500px", overflow: "auto" }}>
            {partners
              .filter(p => p.country === (isBE() ? "BE" : "NL"))
              .map(partner => {
                return {
                  ...partner,
                  distance: searchedLocation
                    ? parseFloat(
                        calculateDistance(
                          partner.lat,
                          partner.lon,
                          searchedLocation.lat,
                          searchedLocation.lon
                        )
                      )
                    : 0,
                };
              })
              .sort((a, b) => {
                return a.distance - b.distance;
              })
              .map(partner => {
                return (
                  <div
                    className={`map-item ${fullWidth ? "full" : ""} ${
                      fullWidth ? "pointer" : ""
                    } ${
                      fullWidth &&
                      selectedPartner &&
                      selectedPartner.id === partner.id
                        ? " selected"
                        : ""
                    } ${clicked === partner.id ? " selected" : null}`}
                    onClick={() => {
                      setSelectedPartner(partner);
                      selectPartner(partner);
                      setClicked(partner.id);
                    }}
                  >
                    <b>{partner.businessName}</b>
                    <div>
                      {partner.zipcode} - {partner.city}
                    </div>
                    <div>
                      <Link to={"/partners/" + partner.id} className="link">
                        Meer info
                      </Link>
                    </div>
                    {searchedLocation ? (
                      <div className="range">
                        <FaMapMarkerAlt className="icon" />
                        <b>
                          {calculateDistance(
                            partner.lat,
                            partner.lon,
                            searchedLocation.lat,
                            searchedLocation.lon
                          )}{" "}
                          km
                        </b>
                      </div>
                    ) : null}
                  </div>
                );
              })}
          </div>
        </div>
        <div className={`map ${fullWidth ? "w-100 full" : ""}`}>
          {fullWidth ? null : (
            <form
              onSubmit={async e => {
                e.preventDefault();
                await getCorrectLatLon();
              }}
            >
              <input
                className="input"
                type="text"
                placeholder={t("partners.placeholder")}
                value={search}
                onChange={e => {
                  setSearch(e.target.value);
                }}
              />
            </form>
          )}

          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyDuRTBLzqmACIusTyAvCDWf9dY6ZSrr6gU",
            }}
            center={{
              lat: searchedLocation
                ? searchedLocation.lat
                : isBE()
                ? 50.85045
                : 52.373882,
              lng: searchedLocation
                ? searchedLocation.lon
                : isBE()
                ? 4.34878
                : 4.891711,
            }}
            defaultZoom={searchedLocation ? 14 : 7}
          >
            {partners
              .filter(p => p.country === (isBE() ? "BE" : "NL"))
              .map(partner => {
                return (
                  <Marker
                    lat={partner.lat}
                    lng={partner.lon}
                    info={partner}
                    setClicked={c => {
                      setClicked(c);
                    }}
                    reset={clicked}
                  />
                );
              })}
            {searchedLocation ? (
              <SearchMarker
                lat={searchedLocation.lat}
                lng={searchedLocation.lon}
              />
            ) : null}
          </GoogleMapReact>
        </div>
      </div>
      {!fullWidth ? (
        <div className="containerTransparant">
          <h1 className="mt-auto">{t("partners.prefer")}</h1>
          <h1 className="green">{t("partners.noProblem")}</h1>
          <p>{t("partners.description")}</p>
        </div>
      ) : null}
    </>
  );
}
