import React, { useEffect, useState } from "react";
import "./Checkout.css";
import { Redirect } from "react-router-dom";
import { CartView } from "./Partners";
import { Tyre } from "./api/TyreApi";
import { PublicPartner } from "./api/AuthApi";
import { Checkbox } from "./Checkbox";
import { CheckoutData } from "./App";
import { isBE, isFR, isNL } from "./Utils";
import { useTranslation } from "react-i18next";
import { getCheckoutSave } from "./api/CheckoutApi";

export function Checkout({
  cart,
  deliver,
  setCheckoutData,
}: {
  cart: {
    id: string;
    brand: string;
    name: string;
    count: number;
    price: number;
    tyre: Tyre;
  }[];
  deliver: boolean;
  setCheckoutData: (data: CheckoutData) => void;
}) {
  const { t, i18n } = useTranslation("general");

  const [data, setData] = useState({
    tyres: cart.map(items => {
      return {
        tyreId: items.id,
        amount: items.count,
      };
    }),
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    partnerId: undefined,
    deliveryStreet: "",
    deliveryNumber: "",
    deliveryZipcode: "",
    deliveryCity: "",
    deliveryCountry: isBE() || isFR() ? "BE" : "NL",
    billingStreet: "",
    billingNumber: "",
    billingZipcode: "",
    billingCity: "",
    billingCountry: isBE() || isFR() ? "BE" : "NL",
  });
  const [selectedPartner, setSelectedPartner] = useState<
    undefined | PublicPartner
  >(undefined);
  const [newsletter, setNewsletter] = useState(false);
  const [terms, setTerms] = useState(false);
  const [message, setMessage] = useState<null | string>(null);
  const [redirectToPayment, setRedirectToPayment] = useState(false);
  const [didSave, setDidSave] = useState(false);

  const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  /*useEffect(() => {
    if (didSave) {
      return;
    }

    if (!validateEmail(data.email)) {
      return;
    }

    getCheckoutSave(
      {
        tyres: data.tyres,
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
      },
      i18n.language
    ).then(() => {
      setDidSave(true);
    });
  }, [data, didSave]);*/

  if (redirectToPayment) {
    return <Redirect push to={"payment"} />;
  }

  return (
    <div className="checkout-container">
      <div className="progress">
        <div className="item active">{t("data")}</div>
        <div className="item">{t("payment")}</div>
        <div className="item">{t("confirmation")}</div>
      </div>
      <form
        autoComplete="on"
        id="proceedToPayment"
        onSubmit={async e => {
          e.preventDefault();

          if (!terms) {
            setMessage(t("acceptTerms"));

            setTimeout(() => {
              setMessage(null);
            }, 1000 * 3);

            return;
          }

          if (
            !data.firstName &&
            !data.lastName &&
            !data.email &&
            !data.phoneNumber &&
            !data.deliveryCity &&
            !data.deliveryStreet &&
            !data.deliveryZipcode
          ) {
            setMessage(t("completeInputs"));

            setTimeout(() => {
              setMessage(null);
            }, 1000 * 3);

            return;
          }

          if (!deliver && !selectedPartner) {
            setMessage(t("selectPartner"));

            setTimeout(() => {
              setMessage(null);
            }, 1000 * 3);

            return;
          }

          setCheckoutData({
            ...data,
            deliveryStreet: selectedPartner
              ? selectedPartner.street
              : data.deliveryStreet,
            deliveryNumber: selectedPartner
              ? selectedPartner.number
              : data.deliveryNumber,
            deliveryZipcode: selectedPartner
              ? selectedPartner.zipcode
              : data.deliveryZipcode,
            deliveryCity: selectedPartner
              ? selectedPartner.city
              : data.deliveryCity,
            billingStreet: selectedPartner
              ? selectedPartner.street
              : data.deliveryStreet,
            billingNumber: selectedPartner
              ? selectedPartner.number
              : data.deliveryNumber,
            billingZipcode: selectedPartner
              ? selectedPartner.zipcode
              : data.deliveryZipcode,
            billingCity: selectedPartner
              ? selectedPartner.city
              : data.deliveryCity,
            billingCountry: "BE",
            partnerId: selectedPartner ? selectedPartner.id : undefined,
            newsLetter: newsletter,
          });

          getCheckoutSave(
            {
              tyres: data.tyres,
              email: data.email,
              firstName: data.firstName,
              lastName: data.lastName,
            },
            i18n.language
          ).then(() => {
            setDidSave(true);
          });

          setRedirectToPayment(true);
        }}
      >
        <div className="flex justify-between checkout-wrapper">
          <div className="contact-details">
            <h1>{t("contactData")}</h1>
            <label htmlFor="email">{t("email")}</label>
            <input
              className="input"
              type="email"
              name="email"
              id="email"
              placeholder={t("email")}
              required={true}
              value={data.email}
              onBlur={e => {
                const email = e.target.value;
                if (didSave) {
                  return;
                }

                if (!validateEmail(email)) {
                  return;
                }

                getCheckoutSave(
                  {
                    tyres: data.tyres,
                    email: email,
                    firstName: data.firstName,
                    lastName: data.lastName,
                  },
                  i18n.language
                );

                setDidSave(true);
              }}
              onChange={e => {
                setData({
                  ...data,
                  email: e.target.value,
                });
              }}
            />

            <label htmlFor="given-name">{t("firstName")}</label>
            <input
              className="input"
              type="text"
              name="given-name"
              id="given-name"
              placeholder={t("firstName")}
              required={true}
              value={data.firstName}
              onChange={e => {
                setData({
                  ...data,
                  firstName: e.target.value,
                });
              }}
            />

            <label htmlFor="family-name">{t("name")}</label>
            <input
              className="input"
              type="text"
              name="family-name"
              id="family-name"
              placeholder={t("name")}
              required={true}
              value={data.lastName}
              onChange={e => {
                setData({
                  ...data,
                  lastName: e.target.value,
                });
              }}
            />

            <label htmlFor="tel">{t("phone")}</label>
            <input
              className="input"
              type="text"
              name="tel"
              id="tel"
              placeholder={t("phone")}
              required={true}
              value={data.phoneNumber}
              onChange={e => {
                setData({
                  ...data,
                  phoneNumber: e.target.value,
                });
              }}
            />

            <Checkbox
              title={t("newsletter")}
              value={newsletter}
              onClick={checked => {
                setNewsletter(checked);
              }}
            />
            <Checkbox
              title={t("terms")}
              value={terms}
              onClick={checked => {
                setTerms(checked);
              }}
            />

            {message ? <div className="red mb-4">{message}</div> : null}
          </div>
          <div className="total total-wrapper">
            <div className="total">
              {deliver ? (
                <>
                  <h1>{t("deliverAddress")}</h1>
                  <label htmlFor="address-line1">{t("street")}</label>
                  <input
                    className="input"
                    type="text"
                    name="address-line1"
                    id="address-line1"
                    placeholder={t("street")}
                    required={true}
                    value={data.deliveryStreet}
                    onChange={e => {
                      setData({
                        ...data,
                        deliveryStreet: e.target.value,
                      });
                    }}
                  />

                  <label htmlFor={"number"}>{t("number")}</label>
                  <input
                    className="input"
                    type="text"
                    name="number"
                    id="number"
                    placeholder={t("number")}
                    required={true}
                    value={data.deliveryNumber}
                    onChange={e => {
                      setData({
                        ...data,
                        deliveryNumber: e.target.value,
                      });
                    }}
                  />

                  <label htmlFor="postal-code">{t("zipcode")}</label>
                  <input
                    className="input"
                    type="text"
                    name="postal-code"
                    id="postal-code"
                    placeholder={t("zipcode")}
                    required={true}
                    value={data.deliveryZipcode}
                    onChange={e => {
                      setData({
                        ...data,
                        deliveryZipcode: e.target.value,
                      });
                    }}
                  />

                  <label htmlFor="place">{t("place")}</label>
                  <input
                    className="input"
                    type="text"
                    name="place"
                    id="place"
                    placeholder={t("place")}
                    required={true}
                    value={data.deliveryCity}
                    onChange={e => {
                      setData({
                        ...data,
                        deliveryCity: e.target.value,
                      });
                    }}
                  />
                  <label>{t("country")}</label>
                  <div>
                    {isBE() ? "België" : null}
                    {isFR() ? "Belgique" : null}
                    {isNL() ? "Nederland" : null}
                  </div>
                </>
              ) : (
                <>
                  <h1>{t("deliveryAndInstallation")}</h1>
                  <CartView
                    title={false}
                    fullWidth={true}
                    selectPartner={setSelectedPartner}
                  />
                </>
              )}
              {message ? <div className="red">{message}</div> : null}
              <button
                className="button mt-4"
                type="submit"
                name="button2"
                value="button2"
              >
                {t("goToPayment")}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
