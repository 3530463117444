import React, { useState } from "react";
import { contact } from "../api/AuthApi";
import { useTranslation } from "react-i18next";

export function ContactSheet() {
  const { t } = useTranslation("general");

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [message, setMessage] = useState("");
  const [adress, setAdress] = useState("");
  const [zipcode, setZipCode] = useState("");
  const [phone, setPhone] = useState("");

  return (
    <div className="containerTransparant">
      <form
        onSubmit={async e => {
          e.preventDefault();

          try {
            await contact(email, firstName, lastName, message);
            setSuccess(true);
            setEmail("");
            setFirstName("");
            setLastName("");
            setMessage("");
            setAdress("");
            setZipCode("");
            setPhone("");
          } catch (e) {
            setError(true);
          }
        }}
      >
        <div className="flex">
          <input
            className="mr-4"
            type="text"
            placeholder={t("name") + "*"}
            required={true}
            value={lastName}
            onChange={e => {
              setLastName(e.target.value);
            }}
          />
          <div className="mr-4" />
          <input
            type="text"
            placeholder={t("firstName") + "*"}
            required={true}
            value={firstName}
            onChange={e => {
              setFirstName(e.target.value);
            }}
          />
        </div>

        <div className="flex">
          <input
            className="mr-4"
            type="text"
            placeholder={t("street") + " + " + t("number")}
            value={adress}
            onChange={e => {
              setAdress(e.target.value);
            }}
          />
          <div className="mr-4" />
          <input
            type="text"
            placeholder={t("place")}
            value={zipcode}
            onChange={e => {
              setZipCode(e.target.value);
            }}
          />
        </div>
        <div className="flex">
          <input
            className="mr-4"
            type="text"
            placeholder={t("phone")}
            value={phone}
            onChange={e => {
              setPhone(e.target.value);
            }}
          />
          <div className="mr-4" />
          <input
            type="email"
            placeholder={t("email") + "*"}
            required={true}
            value={email}
            onChange={e => {
              setEmail(e.target.value);
            }}
          />
        </div>
        <textarea
          placeholder={t("remarks") + "*"}
          required={true}
          value={message}
          onChange={e => {
            setMessage(e.target.value);
          }}
        />
        {error ? <div className="red mt-4 mb-4">{t("baseError")}</div> : null}
        {success ? (
          <div className="green mt-4 mb-4">{t("contactSuccess")}</div>
        ) : null}
        <button className="button">{t("send")}</button>
      </form>
    </div>
  );
}
