import React, { useState } from "react";
import "./Brands.css";
import { useTranslation } from "react-i18next";
import { Brand, getBrands } from "../api/BrandApi";
import { useEffectOnce } from "react-use";
import { capitalize, isFR } from "../Utils";
import { Link } from "react-router-dom";

export function AllBrands({ brands }: { brands: Brand[] }) {
  const { t } = useTranslation("home");

  const [brand, setBrand] = useState<Brand[]>([]);

  useEffectOnce(() => {
    getBrands().then(result => {
      setBrand(result.filter(filter => filter.categoryId !== null));
    });
  });

  const columns = brand.map((brand, index) => (
    <div className="flex flex-column">
      <div key={index} className="brandItem">
        <Link
          to={
            (isFR() ? "/marques-pneus/" : "/bandenmerken/") +
            brand.name
              .toLowerCase()
              .trim()
              .replaceAll(" ", "-") +
            "-" +
            brand.id
          }
          title={capitalize(brand.name)}
        >
          <img src={brand.logo} alt={capitalize(brand.name)} />
        </Link>
      </div>
      <h4 style={{ marginBottom: "10px", marginTop: "-12px" }}>
        {capitalize(brand.name)}
      </h4>
      <Link
        to={
          (isFR() ? "/marques-pneus/" : "/bandenmerken/") +
          brand.name
            .toLowerCase()
            .trim()
            .replaceAll(" ", "-") +
          "-" +
          brand.id
        }
        title={capitalize(brand.name)}
        className="button w-fit mb-4"
      >
        Bekijk merk
      </Link>
    </div>
  ));
  return (
    <div className="containerLightGreen">
      <h2 className="mt-auto">{t("all_our_brands")}</h2>
      <p className="brandDescription">{t("brand_description")}</p>
      <div className="flex wrap">{columns}</div>
    </div>
  );
}
