import React, { useState } from "react";
import { useParams, Link } from "react-router-dom";
import "./Success.css";
import "./Checkout.css";
import { getOrder, Order } from "./api/OrderApi";
import { useEffectOnce } from "react-use";
import { useTranslation } from "react-i18next";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { Helmet } from "react-helmet";
import { getCorrectPrice, isNL } from "./Utils";

export function Success({ deleteCart }: { deleteCart: () => void }) {
  const { t } = useTranslation("success");

  // @ts-ignore
  let { orderId } = useParams();
  const [order, setOrder] = useState<Order | undefined>(undefined);
  const [loading, setLoading] = useState(true);

  const sendDataToGTM = useGTMDispatch();

  const handlePurchase = () => {
    sendDataToGTM({ event: "purchase",
      ecommerce: {
        transaction_id: order?.orderId,
        value: order?.price,
        shipping: order?.transport,
        transaction_cost: order?.transactionCost,
        currency: "EUR",
        items: order?.tyres.map((item) => (
          {
            item_id: item.tyreId,
            quantity: item.amount,
            price: item.price,
            currency: "EUR"
          }
        )),
      },
    });
  };

  useEffectOnce(() => {
    getOrder(orderId).then(result => {
      setOrder(result);

      if (result && result.orderStatus === "Betaald") {
        deleteCart();
        handlePurchase();
      }

      setLoading(false);
    });
  });

  if (loading) {
    return (
      <div className="order-container">
        <>
          <div className="flex justify-center">
            <h1>{t("fetchingOrderTitle")}</h1>
          </div>
          <div className="flex justify-center text-center flex-column items-center">
            {t("fetchingOrderDesc")}
          </div>
        </>
      </div>
    );
  }

  return (
    <div className="order-container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{!isNL() ? t("documentTitle") : "Order afgerond | Banden-Online-Kopen.nl!"}</title>
      </Helmet>
      {order && order.orderStatus === "Betaald" ? (
        <>
          <div className="flex justify-center">
            <h1>{t("orderSuccessTitle")}</h1>
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: t("orderSuccessDesc") }}
            className="flex justify-center text-center flex-column items-center"
          ></div>
        </>
      ) : (
        <>
          <div className="flex justify-center">
            <h1>{t("orderFailureTitle")}</h1>
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: t("orderFailureDesc") }}
            className="flex justify-center text-center"
          ></div>
        </>
      )}
    </div>
  );
}
