import {
  API_ENDPOINT,
  getHeaders,
  Partner,
  PartnerChange,
  PublicPartner,
} from "./AuthApi";

export async function getUnapprovedPartners(page: number): Promise<Partner[]> {
  const result = await fetch(
    `${API_ENDPOINT}/partners/unapproved?page=${page}`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  ).then(r => r.json());

  if (!result.partners) {
    return [];
  }

  return result.partners;
}

export async function updatePartner(partner: Partner): Promise<Partner> {
  return await fetch(`${API_ENDPOINT}/partners/me`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(partner),
  }).then(r => r.json());
}

export async function getPartnerChanges(): Promise<PartnerChange[]> {
  const result = await fetch(`${API_ENDPOINT}/partners/changes`, {
    method: "GET",
    headers: getHeaders(),
  }).then(r => r.json());

  if (!result.changes) {
    return [];
  }

  return result.changes;
}

export async function getPartnerById(id: string): Promise<Partner> {
  const result = await fetch(`${API_ENDPOINT}/partners/${id}`, {
    method: "GET",
    headers: getHeaders(),
  }).then(r => r.json());

  return result.partner;
}

export async function updatePartnerById(partner: Partner): Promise<void> {
  await fetch(`${API_ENDPOINT}/partners/${partner.id}`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(partner),
  }).then(r => r.json());
}

export async function approvePartnerChange(id: string): Promise<void> {
  await fetch(`${API_ENDPOINT}/partners/changes/${id}/approve`, {
    method: "POST",
    headers: getHeaders(),
  }).then(r => r.json());
}

export async function removePartnerChange(id: string): Promise<void> {
  await fetch(`${API_ENDPOINT}/partners/changes/${id}/approve`, {
    method: "DELETE",
    headers: getHeaders(),
  }).then(r => r.json());
}

export async function getApprovedPartners(page: number): Promise<Partner[]> {
  const result = await fetch(`${API_ENDPOINT}/partners/approved?page=${page}`, {
    method: "GET",
    headers: getHeaders(),
  }).then(r => r.json());

  if (!result.partners) {
    return [];
  }

  return result.partners;
}

export async function getInactivePartners(page: number): Promise<Partner[]> {
  const result = await fetch(`${API_ENDPOINT}/partners/inactive?page=${page}`, {
    method: "GET",
    headers: getHeaders(),
  }).then(r => r.json());

  if (!result.partners) {
    return [];
  }

  return result.partners;
}

export async function approvePartner(id: string): Promise<void> {
  await fetch(`${API_ENDPOINT}/partners/${id}/approve`, {
    method: "POST",
    headers: getHeaders(),
  }).then(r => r.json());
}

export async function inactivePartner(id: string): Promise<void> {
  await fetch(`${API_ENDPOINT}/partners/${id}/inactive`, {
    method: "POST",
    headers: getHeaders(),
  }).then(r => r.json());
}

export async function deletePartner(id: string): Promise<void> {
  await fetch(`${API_ENDPOINT}/partners/${id}`, {
    method: "DELETE",
    headers: getHeaders(),
  }).then(r => r.json());
}

export async function getAllPartners(): Promise<PublicPartner[]> {
  const result = await fetch(`${API_ENDPOINT}/partners/list`, {
    method: "GET",
    headers: getHeaders(),
  }).then(r => r.json());

  if (!result.partners) {
    return [];
  }

  return result.partners;
}

export async function getCurentPartner(): Promise<Partner> {
  const result = await fetch(`${API_ENDPOINT}/partners/me`, {
    method: "GET",
    headers: getHeaders(),
  }).then(r => r.json());

  return result.partner;
}

export async function getPublicPartnerById(id: String) {
  const result = await fetch(`${API_ENDPOINT}/partners/list/${id}`, {
    method: "GET",
    headers: getHeaders(),
  }).then(r => r.json());

  return result.partner;
}
