import React, { useState } from "react";
import { Article, getArticles } from "./api/ArticleApi";
import { useEffectOnce } from "react-use";
import { Helmet } from "react-helmet";
import { isFR, isNL } from "./Utils";
import { Link } from "react-router-dom";
import thumbnailImg from "./img/img.png";
import "./Blogpost.css";
import BlogList from "./components/BlogList";

export function Blogs() {
  const [articles, setArticles] = useState<Article[]>([]);

  useEffectOnce(() => {
    getArticles().then(r => {
      setArticles(r);
    });
  })

  return (
    <>
      <Helmet>
        <title>Blog | {isNL() ? "Banden-Online-Kopen.nl" : isFR() ? "Pneus-Enligne.be" : "Banden-Online-Kopen.be"}</title>
        <meta name="description" content=""/>
      </Helmet>
      <div className="containerTransparant">
        <div className="blogsIntro">
          <h1 className="green">Banden online kopen blog</h1>
          <p>
            Op onze blog bij Banden-Online-Kopen hebben we allerlei nuttige informatie
            en tips over banden en het kopen ervan online. Of je nu op zoek bent naar advies
            over het kiezen van de juiste banden voor je auto, motor, vrachtwagen of ander
            voertuig, of gewoon op zoek bent naar leuke feiten en weetjes over banden,
            we hebben het hier allemaal. Bekijk onze verschillende blogposts voor meer
            informatie en laat ons weten als je vragen hebt. We zijn altijd bereid om
            te helpen bij het maken van de beste keuze voor jouw specifieke behoeften.
          </p>
          <p>
            Blijf op de hoogte van onze nieuwste blogposts door onze pagina met het
            overzicht van alle blogposts regelmatig te bezoeken of door ons te volgen
            op social media. We hebben ook een nieuwsbrief waarmee je op de hoogte
            kunt blijven van al onze nieuwste blogposts en aanbiedingen. Schrijf je
            in door je e-mailadres achter te laten op onze website en we zorgen dat
            je altijd op de hoogte bent. Bedankt voor het bezoeken van onze blog bij
            Banden-Online-Kopen!
          </p>
        </div>
        <BlogList />
      </div>
    </>
  );
}
