import React, { useEffect, useState } from "react";
import { getSearchOptions, SearchOptions } from "../api/TyreApi";
import { Brand } from "../api/BrandApi";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffectOnce } from "react-use";
import { Select } from "../Select";
import { parseIntOrReturnValue, sortValue } from "../Utils";
import tyres from "../img/searchImage.jpg";

export enum Selection {
  WIDTH = "width",
  HEIGHT = "height",
  RADIUS = "radius",
  SEASON = "season",
}

export function SearchBarSmall({
  brands,
  options,
}: {
  brands: Brand[];
  options: SearchOptions;
}) {
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [diameter, setDiameter] = useState("");
  const [season, setSeason] = useState("");
  const [localSearchOptions, setLocalSearchOptions] = useState(options);
  const [firstSelected, setFirstSelected] = useState<null | Selection>();
  const history = useHistory();
  const { t } = useTranslation("general");

  useEffectOnce(() => {
    setLocalSearchOptions(options);
  });

  useEffect(() => {
    const params = new URLSearchParams();
    params.set("width", width);
    params.set("height", height);
    params.set("diameter", diameter);
    params.set("season", season);

    getSearchOptions(params.toString()).then(result => {
      setLocalSearchOptions(result);
    });
  }, [width, height, diameter, season]);

  return (
    <form
      onSubmit={e => {
        e.preventDefault();

        const params = new URLSearchParams();
        params.set("width", width);
        params.set("height", height);
        params.set("diameter", diameter);
        params.set("season", season);

        history.push(`/search?${params.toString()}`);
      }}
    >
      <div className="searchBar">
        <img src={tyres} alt="Search image" />
      </div>
      <div className="searchRow flex">
        <div className="search-bar">
          <div className="searchOptions">
            <div className="title">{t("searchBar")}</div>
            <div className="middleOptions">
              <div>
                <Select
                  search={true}
                  title={t("tyre.width").toString()}
                  tooltip={t("tyre.widthTooltip").toString()}
                  options={[{ value: "", label: "" }].concat(
                    (firstSelected === Selection.WIDTH
                      ? options.width
                      : localSearchOptions.width
                    )
                      .map(i => parseIntOrReturnValue(i))
                      .sort(sortValue)
                      .map(certainWidth => ({
                        value: certainWidth.toString(),
                        label: certainWidth.toString(),
                      }))
                  )}
                  selected={width}
                  selectOption={selected => {
                    setWidth(selected);

                    if (!firstSelected) {
                      setFirstSelected(Selection.WIDTH);
                    }

                    if (selected === "" && firstSelected === Selection.WIDTH) {
                      setFirstSelected(null);
                    }
                  }}
                />
              </div>
              <div>
                <Select
                  search={true}
                  title={t("tyre.height").toString()}
                  tooltip={t("tyre.heightTooltip").toString()}
                  options={[{ value: "", label: "" }].concat(
                    (firstSelected === Selection.HEIGHT
                      ? options.height
                      : localSearchOptions.height
                    )
                      .map(i => parseIntOrReturnValue(i))
                      .sort(sortValue)
                      .map(certainHeight => ({
                        value: certainHeight.toString(),
                        label: certainHeight.toString(),
                      }))
                  )}
                  selected={height}
                  selectOption={selected => {
                    setHeight(selected);

                    if (!firstSelected) {
                      setFirstSelected(Selection.HEIGHT);
                    }

                    if (selected === "" && firstSelected === Selection.HEIGHT) {
                      setFirstSelected(null);
                    }
                  }}
                />
              </div>
              <div>
                <Select
                  search={true}
                  title={t("tyre.diameter").toString()}
                  tooltip={t("tyre.diameterTooltip").toString()}
                  options={[{ value: "", label: "" }].concat(
                    (firstSelected === Selection.RADIUS
                      ? options.diameter
                      : localSearchOptions.diameter
                    )
                      .map(i => parseIntOrReturnValue(i))
                      .sort(sortValue)
                      .map(certainRadius => ({
                        value: certainRadius.toString(),
                        label: certainRadius.toString(),
                      }))
                  )}
                  selected={diameter}
                  selectOption={selected => {
                    setDiameter(selected);

                    if (!firstSelected) {
                      setFirstSelected(Selection.RADIUS);
                    }

                    if (selected === "" && firstSelected === Selection.RADIUS) {
                      setFirstSelected(null);
                    }
                  }}
                />
              </div>
              <div>
                <Select
                  search={true}
                  title={t("tyre.season.title").toString()}
                  tooltip=""
                  options={[{ value: "", label: "" }].concat(
                    (firstSelected === Selection.SEASON
                      ? options.season
                      : localSearchOptions.season
                    )
                      .sort()
                      .map(tyre => ({
                        value: tyre.toString(),
                        label: t(tyre.toString()),
                      }))
                  )}
                  selected={season}
                  selectOption={selected => {
                    setSeason(selected);

                    if (!firstSelected) {
                      setFirstSelected(Selection.SEASON);
                    }

                    if (selected === "" && firstSelected === Selection.SEASON) {
                      setFirstSelected(null);
                    }
                  }}
                />
              </div>
              <div>
                <button className="search" type={"submit"}>
                  {t("buttons.search")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
