import React from "react";
import shoppingCart from "../img/shopping_cart.png";
import sun from "../img/sun.svg";
import carImage from "../img/car.svg";
import "./TyreCard.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  getCorrectFuelImage,
  getCorrectKindImage,
  getCorrectNoiseImage,
  getCorrectWetImage,
} from "../Utils";
import { useLocation } from "react-use";
import tyreImg from "../img/tyre.png";

export function TyreCard({
  id,
  profile,
  brand,
  season,
  price,
  vehicleType,
  competitors,
  logos,
  width,
  height,
  radius,
  loadIndex,
  speedIndex,
  wetRoadEfficiency,
  fuelEfficiency,
  brandId,
  noise,
  noiseLevel,
  image,
  topSale,
  promo,
}: {
  id: string;
  profile: string;
  brand: string;
  season: string;
  price: number;
  vehicleType: string;
  competitors: number[];
  logos: string[];
  width: string;
  height: string;
  radius: number;
  loadIndex: string;
  speedIndex: string;
  wetRoadEfficiency: string;
  fuelEfficiency: string;
  noise: number;
  noiseLevel: string;
  brandId: string;
  image: string | null;
  topSale?: boolean;
  promo?: boolean;
}) {
  const location = useLocation();
  const { t } = useTranslation("home");
  const { t: generalT } = useTranslation("general");

  return (
    <div className="tyreCard flex">
      <div className="imageWrapper">
        <Link
          to={`/product/${brand
            .toLowerCase()
            .trim()
            .replaceAll(" ", "-")}-${profile
            .toLowerCase()
            .trim()
            .replaceAll(
              " ",
              "-"
            )}-${width}-${height}-r${radius}-${loadIndex}${speedIndex}-${id}${
            location.search
          }`}
        >
          <img src={image ? image : tyreImg} alt="Voorbeeld band" />
          {topSale ? (
            <div className="ribbon-wrapper">
              <div className="ribbon">Topverkoop!</div>
            </div>
          ) : null}
          {promo ? (
            <div className="ribbon-wrapper">
              <div className="ribbon green">Promo</div>
            </div>
          ) : null}
        </Link>
      </div>
      <div className="tyre-info">
        <div className="brand">
          <b>{brand}</b>
        </div>
        <div>{profile}</div>
        <div className="dimensions flex items-center">
          <img src={getCorrectKindImage(season)} />
          <div className="ml-1">
            {width}/{height} R{radius} {loadIndex} {speedIndex}
          </div>
        </div>
        <div className="ecoLabel">
          <img
            className="efficiency margin"
            src={getCorrectWetImage(wetRoadEfficiency)}
            alt={"road"}
          />
          <img
            className="efficiency"
            src={getCorrectFuelImage(fuelEfficiency)}
            alt={"fuel"}
          />
        </div>
      </div>
      <div className="orderNowAndPrice">
        <div className="price">€{price}</div>
        <Link
          to={`/product/${brand
            .toLowerCase()
            .replaceAll(
              " ",
              "-"
            )}-${profile}-${width}-${height}-r${radius}-${loadIndex}${speedIndex}-${id}${
            location.search
          }`}
          className="button"
        >
          <div className="see-more">{t("see_more")}</div>
        </Link>
      </div>
    </div>
  );
}
