import React from "react";
import "./Loading.css";

export function Loading() {
  return (
    <div className="la-ball-clip-rotate la-dark">
      <div></div>
    </div>
  );
}
