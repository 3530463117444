import React from "react";
import { TermsNl } from "./components/TermsNl";
import { TermsFr } from "./components/TermsFr";
import { Helmet } from "react-helmet";
import { isBE, isFR, isNL } from "./Utils";

export function Terms({ language }: { language: string }) {
  return (
    <div className="containerTransparant">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {"Algemene voorwaarden | Banden-Online-Kopen" +
            isBE()
            ? ".be!"
            : isNL()
              ? ".nl!"
              : isFR()
                ? "Conditions générales | Pneus-Enligne.be!"
                : ""
          }
        </title>
      </Helmet>
      {language === "nl" ? <TermsNl /> : <TermsFr />}
    </div>
  );
}
