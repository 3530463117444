import { Link } from "react-router-dom";
import thumbnailImg from "../img/img.png";
import React, { useState } from "react";
import { Article, getArticles } from "../api/ArticleApi";
import { useEffectOnce } from "react-use";
import { isMobile } from "react-device-detect";

export default function BlogList() {
  const [articles, setArticles] = useState<Article[]>([]);

  useEffectOnce(() => {
    getArticles().then(r => {
      setArticles(r);
    });
  });

  return (
    <>
      <div>
        {articles.map(article => {
          return (
            <>
              <div className={"blogRow"}>
                <div className="imageContainer">
                  <Link to={"/blogs/" + article.slug}>
                    <img src={article.image} />
                  </Link>
                </div>
                <div className="blogPreview">
                  <h2>{article.title}</h2>
                  <p className="blogDesc">
                    {article.text.split(">")[1].slice(0, 150) + "..."}
                  </p>
                  <p>{article.author}</p>
                </div>
                <div className="readMore">
                  <Link to={"/blogs/" + article.slug} className="button">
                    Lees meer
                  </Link>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
}
