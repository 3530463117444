import tooltipImage from "./img/tooltip.svg";
import React from "react";
import "./Select.css";

export function Select({
  title,
  tooltip,
  options,
  selected,
  selectOption,
  search,
}: {
  title: string | false;
  tooltip: string | false;
  options: { label: string; value: string }[];
  selected: string;
  selectOption: (selected: string) => void;
  search: boolean;
}) {
  // TODO: tooltip alt & select design

  return (
    <div className={`select ${search ? "search" : ""}`}>
      {title ? <label>{title}</label> : null}
      {tooltip ? (
        <img src={tooltipImage} alt="Tooltip" data-tip={tooltip} />
      ) : null}
      {title ? <br /> : null}
      <select
        value={selected}
        onChange={item => {
          selectOption(item.target.value);
        }}
      >
        {options.map((option, index) => {
          return (
            <option value={option.value} key={index}>
              {option.label}
            </option>
          );
        })}
      </select>
    </div>
  );
}
