import React from "react";
import zomerbanden from "../img/services/zomerbanden.png";
import winterbanden from "../img/services/winterbanden.png";
import fourseasons from "../img/services/4seasons.png";
import personenwagens from "../img/services/personenwagen.png";
import bestelwagens from "../img/services/van.png";
import suv from "../img/services/suv.png";
import "./Services.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export function Services() {
  const { t } = useTranslation("home");

  const items = [
    {
      image: zomerbanden,
      description: t("season.summer"),
      link: "/tyres/summer",
    },
    {
      image: winterbanden,
      description: t("season.winter"),
      link: "/tyres/winter",
    },
    {
      image: fourseasons,
      description: t("season.allSeason"),
      link: "/tyres/allseason",
    },
    {
      image: personenwagens,
      description: t("season.passengerCar"),
      link: "/",
    },
    {
      image: bestelwagens,
      description: t("season.van"),
      link: "/",
    },
    {
      image: suv,
      description: t("season.SUV"),
      link: "/",
    },
  ];

  const services = items.map((service, index) => (
    <Link to={service.link} key={index} className="serviceItem">
      <img src={service.image} alt={service.description} />
      <p className="boldGreen">{service.description}</p>
    </Link>
  ));
  return (
    <>
      <div className="containerGreen">
        <h2 className="white">{t("services")}</h2>
        <div
          className="serviceDescription"
          dangerouslySetInnerHTML={{ __html: t("service_description") }}
        ></div>
        <div className="flex wrap">{services}</div>
      </div>
      <div
        className="containerTransparant"
        dangerouslySetInnerHTML={{ __html: t("description") }}
      ></div>
    </>
  );
}
