import React, { useState } from "react";
import { SearchBar } from "./components/SearchBar";
import { Brand } from "./api/BrandApi";
import { Pricing } from "./api/AuthApi";
import { SearchOptions, getWebPromo, Tyre } from "./api/TyreApi";
import { Brands } from "./components/Brands";
import { useEffectOnce } from "react-use";
import { isMobile } from "react-device-detect";
import { paginate } from "./Search";
import { TyreToWatch } from "./components/TyreToWatch";
import { getCorrectPrice, isNL } from "./Utils";
import { ServicesBanner } from "./components/ServicesBanner";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

export function getAlternateCanonical() {
  const urls = [
    { url: "https://banden-online-kopen.be", lang: "nl-be" },
    { url: "https://banden-online-kopen.nl", lang: "nl-nl" },
    { url: "https://pneus-enligne.be", lang: "fr-be" },
    { url: "https://banden-koning.nl", lang: "nl-nl" },
    { url: "https://pneusfrance.fr", lang: "fr-fr" },
  ];

  return urls.map(item => {
    return (
      <link
        rel="alternate"
        hrefLang={item.lang}
        href={`${item.url}${window.location.pathname}`}
      />
    );
  });
}

export function AllSeasonTyres({
  brands,
  pricing,
  options,
}: {
  brands: Brand[];
  pricing: Pricing;
  options: SearchOptions;
}) {
  const [promo, setPromo] = useState<Tyre[]>([]);

  useEffectOnce(() => {
    getWebPromo().then(result => {
      setPromo(result.filter(filter => filter.season === "4-Season"));
    });
  });

  const [currentPagePromo, setCurrentPagePromo] = useState(1);
  const pageSize = isMobile ? 1 : 3;

  const getPageDataPromo = () => {
    const paginationData = paginate(promo, currentPagePromo, pageSize);
    return { totalCountPromo: promo.length, dataPromo: paginationData };
  };

  const { totalCountPromo, dataPromo } = getPageDataPromo();

  const pageCountPromo = Math.ceil(totalCountPromo / pageSize);
  const nextPagePromo = (increment: number) => {
    if (
      currentPagePromo + increment <= pageCountPromo &&
      currentPagePromo + increment > 0
    ) {
      setCurrentPagePromo(currentPagePromo + increment);
    } else if (pageCountPromo === currentPagePromo) {
      setCurrentPagePromo(1);
    } else if (increment === -1 && currentPagePromo === 1) {
      setCurrentPagePromo(pageCountPromo);
    }
  };

  const { t } = useTranslation("tyres");

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        {getAlternateCanonical()}
        <link rel="canonical" href={window.location.href} />
        <title>
          {!isNL()
            ? t("allSeasonTyres.documentTitle")
            : "All-Seasonbanden online kopen bij Banden-Online-Kopen.nl!"}
        </title>
        <meta name="description" content={t("allSeasonTyres.meta")} />
      </Helmet>
      <SearchBar brands={brands} options={options} />
      <div className="containerTransparant">
        <h1 className="green">{t("allSeasonTyres.title")}</h1>
        <h2>{t("allSeasonTyres.subTitle")}</h2>
        <div
          dangerouslySetInnerHTML={{ __html: t("allSeasonTyres.mainText") }}
        ></div>
      </div>
      <ServicesBanner />
      <div className="containerTransparant">
        {dataPromo.length > 0 ? (
          <h1 className="green">{t("allSeasonTyres.tyresToWatch")}</h1>
        ) : null}

        <div className="toWatchContainer">
          <div className="tyreToWatchContainer">
            <div className="arrow">
              {pageCountPromo > 1 ? (
                <div className="arrow-left" onClick={() => nextPagePromo(-1)} />
              ) : null}
            </div>
            {dataPromo.map((tyre, index) => {
              const brand = brands.find(b => b.id === tyre.brandId);

              return (
                <TyreToWatch
                  key={index}
                  height={tyre.height}
                  loadIndex={tyre.loadIndex}
                  radius={tyre.radius}
                  speedIndex={tyre.speedIndex}
                  width={tyre.width}
                  id={tyre.id}
                  noise={tyre.noiseLevelDb}
                  noiseLevel={tyre.noiseClass}
                  title={tyre.name}
                  model={brand?.name || ""}
                  price={getCorrectPrice(tyre, pricing)}
                  car={tyre.type}
                  kind={tyre.season}
                  competitors={[]}
                  logos={[]}
                  wetRoadEfficiency={tyre.wetRoadEfficiency}
                  fuelEfficiency={tyre.fuelEfficiency}
                  brandId={tyre.brandId}
                  image={tyre.image}
                />
              );
            })}

            <div className="arrow">
              {pageCountPromo > 1 ? (
                <div className="arrow-right" onClick={() => nextPagePromo(1)} />
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <Brands brands={brands} />
    </div>
  );
}
