import React, { useEffect, useState } from "react";
import { Article, getArticleBySlug, getArticles } from "./api/ArticleApi";
import { useEffectOnce } from "react-use";
import { useParams, Link } from "react-router-dom";
import blogImage from "./img/img.png";
import "./Blogpost.css";
import BlogList from "./components/BlogList";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet";
import { isFR, isNL } from "./Utils";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

export function Blogpost() {
  const [article, setArticle] = useState<Article>({
    id: "",
    title: "",
    slug: "",
    author: "",
    text: "",
    image: "",
  });
  const [articles, setArticles] = useState<Article[]>([]);

  const { articleSlug } = useParams<{ articleSlug: string | undefined }>();

  useEffectOnce(() => {
    if (!articleSlug) return;

    getArticleBySlug(articleSlug).then(article => {
      if (!article) return;
      setArticle(article);
    });
  });

  return (
    <>
      <Helmet>
        <title>
          {article.title + " | " + isFR()
            ? "Pneus-Enligne.be"
            : isNL()
            ? "Banden-Online-Kopen.nl"
            : "Banden-Online-Kopen.be"}
        </title>
        <meta name="description" content={article.text.split(">")[1]} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className="blogHeadingContainer">
        <div className="blogBackground">
          <div className="blogMainImage">
            <img src={article.image} alt={article.title} />
          </div>
          <div className="imageCover">
            <h1 className="blogTitle white">{article.title.toUpperCase()}</h1>
          </div>
        </div>
      </div>

      <div className={!isMobile ? "m-auto w-50" : "m-auto w-90"}>
        <div>
          <div className="breadcrumb mt-4 mb-4">
            <Link to="/">Home</Link> &gt;{" "}
            <Link to="/blogs">Alle blogposts</Link> &gt; {article.title}
          </div>
        </div>
        <div>
          <h2 className="green mt-4">{article.title}</h2>
        </div>
        <div
          className="blogContent"
          dangerouslySetInnerHTML={{
            __html: article.text,
          }}
        />
        <div className="blogFooter">
          <div>{article.author}</div>
          <div>
            <FacebookShareButton
              url={window.location.href}
              quote={"Kom meer te weten over jouw autobanden!"}
              hashtag={"#Banden-Online-Kopen.be"}
            >
              <FacebookIcon className="socialIcon" />
            </FacebookShareButton>
            <TwitterShareButton url={window.location.href}>
              <TwitterIcon className="socialIcon" />
            </TwitterShareButton>
            <WhatsappShareButton url={window.location.href}>
              <WhatsappIcon className="socialIcon" />
            </WhatsappShareButton>
            <EmailShareButton url={window.location.href}>
              <EmailIcon className="socialIcon" />
            </EmailShareButton>
            <LinkedinShareButton url={window.location.href}>
              <LinkedinIcon className="socialIcon" />
            </LinkedinShareButton>
          </div>
        </div>
      </div>

      <div className="morePosts">
        <div className={!isMobile ? "m-auto w-50" : "m-auto w-90"}>
          <h2 className="black">Ontdek meer blogs</h2>
          <div className={""}>
            <BlogList />
          </div>
          <div className="mt-4">
            <Link to="/blogs" className="button">
              Overzicht van alle blogposts
              <FontAwesomeIcon icon={faChevronRight} className="ml-3" />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
