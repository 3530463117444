import React, { useState } from "react";
import "./Login.css";
import { login } from "./api/AuthApi";
import { Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";

export function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [redirect, setRedirect] = useState(false);

  if (redirect) {
    return <Redirect push={true} to="/dashboard" />;
  }

  return (
    <div className="login-container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Login</title>
      </Helmet>
      <h2>Inloggen</h2>
      <form
        onSubmit={async e => {
          e.preventDefault();

          const result = await login(email, password);

          if (typeof result == "string") {
            // TODO: process error message

            return;
          }

          setRedirect(true);
        }}
      >
        <label>E-mail</label>
        <input
          value={email}
          onChange={e => {
            setEmail(e.target.value);
          }}
          className="input"
          type="email"
          placeholder="E-mail"
          required={true}
        />
        <label>Wachtwoord</label>
        <input
          value={password}
          onChange={e => {
            setPassword(e.target.value);
          }}
          className="input"
          type="password"
          placeholder="Wachtwoord"
          required={true}
        />
        <button className="button">Inloggen</button>
      </form>
    </div>
  );
}
