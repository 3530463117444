import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AdminHeader } from "./AdminHeader";
import { Select } from "./Select";
import { Helmet } from "react-helmet";
import { Article, deleteArticle, getArticles } from "./api/ArticleApi";
import { useEffectOnce } from "react-use";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";

export function AdminBlogs() {
  const [articles, setArticles] = useState<Article[]>([]);

  useEffectOnce(() => {
    getArticles().then(result => {
      setArticles(result);
    });
  });

  return (
    <>
      <Helmet>
        <title>Blogposts | Admin</title>
      </Helmet>
      <AdminHeader />
      <div className="admin-dashboard">
        <div className="flex justify-between items-center">
          <h1>Blogposts</h1>
          <Link to={"/admin/blog/new"}>
            <FontAwesomeIcon icon={faPlusSquare} size={"2x"} />
          </Link>
        </div>
        <div>
          <table className="partners-table">
            <thead>
              <tr>
                <th>Titel</th>
                <th>Auteur</th>
                <th>Bijwerken</th>
                <th>Verwijderen</th>
              </tr>
            </thead>
            <tbody>
              {articles.map((article, articleIndex) => (
                <tr key={articleIndex}>
                  <td>{article.title}</td>
                  <td>{article.author}</td>
                  <td>
                    <Link to={"/admin/blog/" + article.id}>
                      <FontAwesomeIcon icon={faPen} size={"1x"} />
                    </Link>
                  </td>
                  <td>
                    <div
                      onClick={async () => {
                        await deleteArticle(article.id);
                        setArticles(
                          articles.filter((a, i) => articleIndex !== i)
                        );
                      }}
                    >
                      <FontAwesomeIcon icon={faTrash} size={"1x"} />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
