export type Token = {
  firstName: string;
  lastName: string;
  language: string;
  email: string;
  admin: boolean;
  exp: number;
};

export type Partner = {
  id: string;
  email: string;
  name: string;
  businessName: string;
  approved: boolean;
  street: string;
  number: string;
  zipcode: string;
  city: string;
  country: string;
  phone: string;
  password: string;
  iban: string;
  vat: string;
  maxInchToMount: number;
  placementPrizes: {
    inch10: number;
    inch11: number;
    inch12: number;
    inch13: number;
    inch14: number;
    inch15: number;
    inch16: number;
    inch17: number;
    inch18: number;
    inch19: number;
    inch20: number;
    inch21: number;
    inch22: number;
    inch23: number;
    inch24: number;
  };
  runFlat: boolean;
  runFlatAmount: number;
  tyreStorage: boolean;
  rimCleaning: boolean;
  aligning: boolean;
  fillNitrogen: boolean;
  startHourWeek: string;
  endHourWeek: string;
  startHourSaturday: string;
  endHourSaturday: string;
  startHourSunday: string;
  endHourSunday: string;
  startHourWeekMid: string;
  endHourWeekMid: string;
  startHourSaturdayMid: string;
  endHourSaturdayMid: string;
  startHourSundayMid: string;
  endHourSundayMid: string;
  startHourTue: string;
  endHourTue: string;
  startHourTueMid: string;
  endHourTueMid: string;
  startHourWed: string;
  endHourWed: string;
  startHourWedMid: string;
  endHourWedMid: string;
  startHourThu: string;
  endHourThu: string;
  startHourThuMid: string;
  endHourThuMid: string;
  startHourFri: string;
  endHourFri: string;
  startHourFriMid: string;
  endHourFriMid: string;
  inactive: boolean;
  inactiveAndChanges: boolean;
  mobile: boolean;
};

export type PartnerChange = {
  id: string;
  name: string;
  street: string;
  number: string;
  zipcode: string;
  city: string;
  country: string;
  iban: string;
  vat: string;
};

export type PublicPartner = {
  id: string;
  email: string;
  name: string;
  businessName: string;
  street: string;
  number: string;
  zipcode: string;
  city: string;
  country: string;
  phone: string;
  lat: number;
  lon: number;
  placementPrizes: {
    inch10: number;
    inch11: number;
    inch12: number;
    inch13: number;
    inch14: number;
    inch15: number;
    inch16: number;
    inch17: number;
    inch18: number;
    inch19: number;
    inch20: number;
    inch21: number;
    inch22: number;
    inch23: number;
    inch24: number;
  };
  tyreStorage: boolean;
  rimCleaning: boolean;
  aligning: boolean;
  fillNitrogen: boolean;
};

export type PersonalInformation = {
  email: string;
  name: string;
  businessName: string;
  street: string;
  number: string;
  zipcode: string;
  city: string;
  country: string;
  phone: string;
  password: string;
  iban: string;
  vat: string;
  priceType: string;
  transportCost: number;
  recupelC1: number;
  recupelC2: number;
  onePieceExtra: number;
};

export type Pricing = {
  vat: number;
  priceType: string;
  transportCost: number;
  recupelC1: number;
  recupelC2: number;
  onePieceExtra: number;
};

export type CustomResponse = {
  error?: string;
  ok: boolean;
};

export function getHeaders() {
  return {
    Authorization: `JWT ${localStorage.getItem("token")}`,
    "Content-type": "application/json",
  };
}

export async function login(
  email: string,
  password: string
): Promise<Token | string> {
  const result = await fetch(`${API_ENDPOINT}/auth/login`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      email: email,
      password: password,
    }),
  }).then(r => r.json());

  if (result.token) {
    localStorage.setItem("token", result.token);

    const token_parts = result.token.split(/\./);
    return JSON.parse(window.atob(token_parts[1]));
  }

  return result.message;
}

export async function resetPassword(
  token: string,
  password: string,
  lang: string
): Promise<string> {
  const result = await fetch(`${API_ENDPOINT}/auth/password?lang=${lang}`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      id: token,
      password: password,
    }),
  }).then(r => r.json());

  return result.message;
}

export async function forgotPassword(
  email: string,
  lang: string
): Promise<string> {
  const result = await fetch(`${API_ENDPOINT}/auth/forgot?lang=${lang}`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      email: email,
    }),
  }).then(r => r.json());

  return result.message;
}

export async function registerPartner(
  partner: Partner,
  lang: string
): Promise<Token | string> {
  const result = await fetch(
    `${API_ENDPOINT}/auth/partner/register?lang=${lang}`,
    {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify(partner),
    }
  ).then(r => r.json());

  if (result.token) {
    localStorage.setItem("token", result.token);

    const token_parts = result.token.split(/\./);
    return JSON.parse(window.atob(token_parts[1]));
  }

  return result.message;
}

export const API_ENDPOINT = process.env.REACT_APP_API_URL;

export function getDecodedToken(): Token {
  const token = localStorage.getItem("token");

  if (token === null) {
    return {
      firstName: "",
      lastName: "",
      language: "nl",
      email: "",
      admin: false,
      exp: 0,
    };
  }

  const token_parts = token.split(/\./);
  return JSON.parse(window.atob(token_parts[1]));
}

export function isAdmin() {
  const token = getDecodedToken();

  return token.admin;
}

export async function getVatAndPricing(lang: string): Promise<Pricing> {
  const result = await fetch(`${API_ENDPOINT}/auth/pricing?lang=${lang}`, {
    method: "GET",
    headers: getHeaders(),
  }).then(r => r.json());

  return result;
}

export async function getPersonalInformation(
  nl?: boolean
): Promise<PersonalInformation> {
  const result = await fetch(
    `${API_ENDPOINT}/auth/me?country=${nl ? "nl" : "be"}`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  ).then(r => r.json());

  return result;
}

export async function updatePersonalInformation(
  priceType: string,
  vat: string,
  transportCost: number,
  recupelC1: number,
  recupelC2: number,
  onePieceExtra: number,
  nl?: boolean
): Promise<void> {
  const result = await fetch(
    `${API_ENDPOINT}/auth/me?country=${nl ? "nl" : "be"}`,
    {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify({
        priceType: priceType,
        vat: vat,
        transportCost: transportCost,
        recupelC1: recupelC1,
        recupelC2: recupelC2,
        onePieceExtra: onePieceExtra,
      }),
    }
  ).then(r => r.json());
}

export async function subscribe(email: string): Promise<void> {
  const result = await fetch(`${API_ENDPOINT}/subscribe`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      email: email,
    }),
  }).then(r => r.json());
}

export async function contact(
  email: string,
  firstName: string,
  lastName: string,
  message: string
): Promise<void> {
  const result = await fetch(`${API_ENDPOINT}/contact`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      email: email,
      firstName: firstName,
      lastName: lastName,
      message: message,
    }),
  }).then(r => r.json());
}
