import React, { useState } from "react";
import { AdminHeader } from "./AdminHeader";
import { useEffectOnce } from "react-use";
import {
  createPaymentMethods,
  listMethodsByCountry,
  PaymentMethod,
} from "./api/PaymentApi";
import { Select } from "./Select";
import { uniqueId } from "./uniqueId";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import ApplePay from "./img/applepay.svg";
import Bancontact from "./img/bancontact.svg";
import Sepa from "./img/sepa.svg";
import Belfius from "./img/belfius.svg";
import Visa from "./img/visa.svg";
import Klarna from "./img/klarna.svg";
import Eps from "./img/eps.svg";
import Giftcard from "./img/giftcards.svg";
import Ideal from "./img/ideal.svg";
import Kbc from "./img/kbc.svg";
import Paypal from "./img/paypal.svg";
import Przelewy24 from "./img/p24.svg";
import Giropay from "./img/giropay.svg";
import Paysafe from "./img/paysafecard.svg";
import { Helmet } from "react-helmet";

export const availableMethods = [
  { label: "Apple pay", value: "applepay", img: ApplePay },
  { label: "Bancontact", value: "bancontact", img: Bancontact },
  { label: "Bank", value: "banktransfer", img: Sepa },
  { label: "Belfius", value: "belfius", img: Belfius },
  { label: "Creditcard", value: "creditcard", img: Visa },
  { label: "Direct", value: "directdebit", img: Sepa },
  { label: "Eps", value: "eps", img: Eps },
  { label: "Giftcard", value: "giftcard", img: Giftcard },
  { label: "Giropay", value: "giropay", img: Giropay },
  { label: "Ideal", value: "ideal", img: Ideal },
  { label: "KBC", value: "kbc", img: Kbc },
  { label: "Klarna Pay later", value: "klarnapaylater", img: Klarna },
  { label: "Klarna Slice it", value: "klarnasliceit", img: Klarna },
  { label: "Klarna Pay now", value: "klarnapaynow", img: Klarna },
  { label: "Paypal", value: "paypal", img: Paypal },
  { label: "PaySafeCard", value: "paysafecard", img: Paysafe },
  { label: "Przelewy24", value: "przelewy24", img: Przelewy24 },
  { label: "Sofort", value: "sofort", img: Klarna },
];

export function AdminMethods() {
  const [methods, setMethods] = useState<PaymentMethod[]>([]);
  const [settingsSavedMessage, setSettingsSavedMessage] = useState<null | {
    success: boolean;
    message: string;
  }>(null);

  useEffectOnce(() => {
    listMethodsByCountry("be").then(r => {
      listMethodsByCountry("nl").then(r1 => {
        setMethods(r.concat(r1));
      });
    });
  });

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Betaalmethodes | Admin</title>
      </Helmet>
      <AdminHeader />
      <div className="admin-dashboard">
        <div className="w-45">
          <form
            onSubmit={async e => {
              e.preventDefault();

              await createPaymentMethods(
                methods
                  .filter(
                    m => m.name !== "" || !m.costFixed || !m.costPercentage
                  )
                  .map(item => {
                    return {
                      id: item.id,
                      name: item.name,
                      country: item.country,
                      costFixed: item.costFixed ? item.costFixed : 0,
                      costPercentage: item.costPercentage
                        ? item.costPercentage
                        : 0,
                    };
                  })
              );

              setSettingsSavedMessage({
                success: true,
                message: "Opgeslagen!",
              });
            }}
          >
            <h1>Betaalmethoden</h1>

            <div className="flex">
              <div className="mr-4">
                <h2>België</h2>
                {methods
                  .filter(m => m.country === "be")
                  .map(item => {
                    return (
                      <div>
                        <div className="flex">
                          <label>Betaalmethode</label>
                          <div
                            className="red pointer ml-auto"
                            onClick={() => {
                              setMethods(methods.filter(m => m.id !== item.id));
                            }}
                          >
                            <FontAwesomeIcon icon={faTimes} />
                          </div>
                        </div>
                        <Select
                          title={""}
                          tooltip={false}
                          options={availableMethods}
                          selected={item.name}
                          selectOption={selected => {
                            setMethods(
                              methods.map(method => {
                                if (method.id === item.id) {
                                  return {
                                    ...item,
                                    name: selected,
                                  };
                                }

                                return method;
                              })
                            );
                          }}
                          search={false}
                        />
                        <label>Transactiekost (€)</label>
                        <input
                          type="number"
                          min={0}
                          step={0.01}
                          value={item.costFixed}
                          onChange={e => {
                            const cost = parseFloat(e.target.value);

                            setMethods(
                              methods.map(method => {
                                if (method.id === item.id) {
                                  return {
                                    ...item,
                                    costFixed: cost,
                                  };
                                }

                                return method;
                              })
                            );
                          }}
                        />
                        <label>Transactiekost (%)</label>
                        <input
                          type="number"
                          min={0}
                          step={0.01}
                          value={item.costPercentage}
                          onChange={e => {
                            const cost = parseFloat(e.target.value);

                            setMethods(
                              methods.map(method => {
                                if (method.id === item.id) {
                                  return {
                                    ...item,
                                    costPercentage: cost,
                                  };
                                }

                                return method;
                              })
                            );
                          }}
                        />
                        <hr />
                      </div>
                    );
                  })}
                <div
                  className="text-button"
                  onClick={() => {
                    setMethods(
                      methods.concat({
                        id: uniqueId(),
                        name: "applepay",
                        country: "be",
                        costFixed: 0,
                        costPercentage: 0,
                      })
                    );
                  }}
                >
                  + betaalmethode toevoegen
                </div>
              </div>
              <div className="ml-5">
                <h2>Nederland</h2>
                {methods
                  .filter(m => m.country === "nl")
                  .map(item => {
                    return (
                      <div>
                        <div className="flex">
                          <label>Betaalmethode</label>
                          <div
                            className="red pointer ml-auto"
                            onClick={() => {
                              setMethods(methods.filter(m => m.id !== item.id));
                            }}
                          >
                            <FontAwesomeIcon icon={faTimes} />
                          </div>
                        </div>
                        <Select
                          title={""}
                          tooltip={false}
                          options={availableMethods}
                          selected={item.name}
                          selectOption={selected => {
                            setMethods(
                              methods.map(method => {
                                if (method.id === item.id) {
                                  return {
                                    ...item,
                                    name: selected,
                                  };
                                }

                                return method;
                              })
                            );
                          }}
                          search={false}
                        />
                        <label>Transactiekost (€)</label>
                        <input
                          type="number"
                          min={0}
                          step={0.01}
                          value={item.costFixed}
                          onChange={e => {
                            const cost = parseFloat(e.target.value);

                            setMethods(
                              methods.map(method => {
                                if (method.id === item.id) {
                                  return {
                                    ...item,
                                    costFixed: cost,
                                  };
                                }

                                return method;
                              })
                            );
                          }}
                        />
                        <label>Transactiekost (%)</label>
                        <input
                          type="number"
                          min={0}
                          step={0.01}
                          value={item.costPercentage}
                          onChange={e => {
                            const cost = parseFloat(e.target.value);

                            setMethods(
                              methods.map(method => {
                                if (method.id === item.id) {
                                  return {
                                    ...item,
                                    costPercentage: cost,
                                  };
                                }

                                return method;
                              })
                            );
                          }}
                        />
                        <hr />
                      </div>
                    );
                  })}
                <div
                  className="text-button"
                  onClick={() => {
                    setMethods(
                      methods.concat({
                        id: uniqueId(),
                        name: "applepay",
                        country: "nl",
                        costFixed: 0,
                        costPercentage: 0,
                      })
                    );
                  }}
                >
                  + betaalmethode toevoegen
                </div>
              </div>
            </div>
            {settingsSavedMessage ? (
              <div className={`${settingsSavedMessage.success ? "" : "red"}`}>
                {settingsSavedMessage.message}
              </div>
            ) : null}
            <button className="button mt-4" type="submit">
              Opslaan
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
