import React, { useState } from "react";
import "./PartnerRegister.css";
import { Checkbox } from "./Checkbox";
import { Select } from "./Select";
import { Partner, registerPartner } from "./api/AuthApi";
import { getHour, getMinute, isBE, isFR, isNL } from "./Utils";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { FaChevronDown } from "@react-icons/all-files/fa/FaChevronDown";
import { FaChevronUp } from "@react-icons/all-files/fa/FaChevronUp";
import { FaEuroSign } from "@react-icons/all-files/fa/FaEuroSign";

function PartnerRegisterSuccess() {
  const { t } = useTranslation("partnerLogin");

  return (
    <div className="register-container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("success.pagetitle")}</title>
      </Helmet>
      <h1>{t("success.title")}</h1>
      <h2 className="green">{t("success.subtitle")}</h2>
      <div dangerouslySetInnerHTML={{ __html: t("success.p1") }}></div>
      <p></p>
      <Link to="/dashboard" className="button">
        {t("success.btn")}
      </Link>
    </div>
  );
}

export function PartnerRegister() {
  const { t, i18n } = useTranslation("partnerLogin");

  const [registered, setRegistered] = useState(false);
  const [weekdaysSame, setWeekdaysSame] = useState(false);
  const [data, setData] = useState<{
    id: string;
    email: string;
    name: string;
    businessName: string;
    approved: boolean;
    street: string;
    number: string;
    zipcode: string;
    city: string;
    country: string;
    phone: string;
    password: string;
    iban: string;
    vat: string;
    maxInchToMount: number;
    placementPrizes: {
      inch10: string;
      inch11: string;
      inch12: string;
      inch13: string;
      inch14: string;
      inch15: string;
      inch16: string;
      inch17: string;
      inch18: string;
      inch19: string;
      inch20: string;
      inch21: string;
      inch22: string;
      inch23: string;
      inch24: string;
    };
    runFlat: boolean;
    runFlatAmount: string;
    tyreStorage: boolean;
    rimCleaning: boolean;
    aligning: boolean;
    fillNitrogen: boolean;
    startHourWeek: string;
    endHourWeek: string;
    startHourSaturday: string;
    endHourSaturday: string;
    startHourSunday: string;
    endHourSunday: string;
    startHourWeekMid: string;
    endHourWeekMid: string;
    startHourSaturdayMid: string;
    endHourSaturdayMid: string;
    startHourSundayMid: string;
    endHourSundayMid: string;
    startHourTue: string;
    endHourTue: string;
    startHourTueMid: string;
    endHourTueMid: string;
    startHourWed: string;
    endHourWed: string;
    startHourWedMid: string;
    endHourWedMid: string;
    startHourThu: string;
    endHourThu: string;
    startHourThuMid: string;
    endHourThuMid: string;
    startHourFri: string;
    endHourFri: string;
    startHourFriMid: string;
    endHourFriMid: string;
    inactive: boolean;
    inactiveAndChanges: boolean;
  }>({
    id: "",
    approved: false,
    inactive: false,
    email: "",
    name: "",
    businessName: "",
    street: "",
    number: "",
    zipcode: "",
    city: "",
    country: isBE() ? "BE" : "NL",
    phone: "",
    password: "",
    iban: "",
    vat: "",
    maxInchToMount: 10,
    placementPrizes: {
      inch10: "0",
      inch11: "0",
      inch12: "0",
      inch13: "0",
      inch14: "0",
      inch15: "0",
      inch16: "0",
      inch17: "0",
      inch18: "0",
      inch19: "0",
      inch20: "0",
      inch21: "0",
      inch22: "0",
      inch23: "0",
      inch24: "0",
    },
    runFlat: false,
    runFlatAmount: "0",
    tyreStorage: false,
    rimCleaning: false,
    aligning: false,
    fillNitrogen: false,
    startHourWeek: "",
    endHourWeek: "",
    startHourSaturday: "",
    endHourSaturday: "",
    startHourSunday: "",
    endHourSunday: "",
    startHourWeekMid: "",
    endHourWeekMid: "",
    startHourSaturdayMid: "",
    endHourSaturdayMid: "",
    startHourSundayMid: "",
    endHourSundayMid: "",
    startHourTue: "",
    endHourTue: "",
    startHourTueMid: "",
    endHourTueMid: "",
    startHourWed: "",
    endHourWed: "",
    startHourWedMid: "",
    endHourWedMid: "",
    startHourThu: "",
    endHourThu: "",
    startHourThuMid: "",
    endHourThuMid: "",
    startHourFri: "",
    endHourFri: "",
    startHourFriMid: "",
    endHourFriMid: "",
    inactiveAndChanges: false,
  });
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [showExtra, setShowExtra] = useState(false);
  const [mobile, setMobile] = useState(false);
  const [terms, setTerms] = useState(false);

  function testPrices() {
    try {
      const _a = data.placementPrizes.inch10
        ? parseFloat(data.placementPrizes.inch10.replaceAll(",", "."))
        : 1;
      const _b = data.placementPrizes.inch11
        ? parseFloat(data.placementPrizes.inch11.replaceAll(",", "."))
        : 1;
      const _c = data.placementPrizes.inch12
        ? parseFloat(data.placementPrizes.inch12.replaceAll(",", "."))
        : 1;
      const _d = data.placementPrizes.inch13
        ? parseFloat(data.placementPrizes.inch13.replaceAll(",", "."))
        : 1;
      const _e = data.placementPrizes.inch14
        ? parseFloat(data.placementPrizes.inch14.replaceAll(",", "."))
        : 1;
      const _f = data.placementPrizes.inch15
        ? parseFloat(data.placementPrizes.inch15.replaceAll(",", "."))
        : 1;
      const _g = data.placementPrizes.inch16
        ? parseFloat(data.placementPrizes.inch16.replaceAll(",", "."))
        : 1;
      const _h = data.placementPrizes.inch17
        ? parseFloat(data.placementPrizes.inch17.replaceAll(",", "."))
        : 1;
      const _i = data.placementPrizes.inch18
        ? parseFloat(data.placementPrizes.inch18.replaceAll(",", "."))
        : 1;
      const _j = data.placementPrizes.inch19
        ? parseFloat(data.placementPrizes.inch19.replaceAll(",", "."))
        : 1;
      const _k = data.placementPrizes.inch20
        ? parseFloat(data.placementPrizes.inch20.replaceAll(",", "."))
        : 1;
      const _l = data.placementPrizes.inch21
        ? parseFloat(data.placementPrizes.inch21.replaceAll(",", "."))
        : 1;
      const _n = data.placementPrizes.inch22
        ? parseFloat(data.placementPrizes.inch22.replaceAll(",", "."))
        : 1;
      const _o = data.placementPrizes.inch23
        ? parseFloat(data.placementPrizes.inch23.replaceAll(",", "."))
        : 1;
      const _p = data.placementPrizes.inch23
        ? parseFloat(data.placementPrizes.inch23.replaceAll(",", "."))
        : 1;
      const _q = data.placementPrizes.inch24
        ? parseFloat(data.placementPrizes.inch24.replaceAll(",", "."))
        : 1;

      if (
        _a >= 0 &&
        _b >= 0 &&
        _c >= 0 &&
        _d >= 0 &&
        _e >= 0 &&
        _f >= 0 &&
        _g >= 0 &&
        _h >= 0 &&
        _i >= 0 &&
        _j >= 0 &&
        _k >= 0 &&
        _l >= 0 &&
        _n >= 0 &&
        _o >= 0 &&
        _p >= 0 &&
        _q >= 0
      ) {
        return true;
      }

      return false;
    } catch (e) {
      return false;
    }
  }

  return (
    <>
      {registered ? (
        <PartnerRegisterSuccess />
      ) : (
        <div className="register-container">
          <h2>{t("title")}</h2>
          <p>{t("register.intro")}</p>
          <form
            id="partnerRegister"
            onSubmit={async e => {
              e.preventDefault();

              if (!testPrices()) {
                setError(
                  "De ingegeven prijzen zijn niet geldig. Gelieve enkel getallen in te vullen."
                );
                return;
              }

              if (!terms) {
                setError("Gelieve de algemene voorwaarden te accepteren.");
                return;
              }

              const result = await registerPartner(
                {
                  ...data,
                  placementPrizes: {
                    inch10: data.placementPrizes.inch10
                      ? parseFloat(
                          data.placementPrizes.inch10.replaceAll(",", ".")
                        )
                      : 0,
                    inch11: data.placementPrizes.inch11
                      ? parseFloat(
                          data.placementPrizes.inch11.replaceAll(",", ".")
                        )
                      : 0,
                    inch12: data.placementPrizes.inch12
                      ? parseFloat(
                          data.placementPrizes.inch12.replaceAll(",", ".")
                        )
                      : 0,
                    inch13: data.placementPrizes.inch13
                      ? parseFloat(
                          data.placementPrizes.inch13.replaceAll(",", ".")
                        )
                      : 0,
                    inch14: data.placementPrizes.inch14
                      ? parseFloat(
                          data.placementPrizes.inch14.replaceAll(",", ".")
                        )
                      : 0,
                    inch15: data.placementPrizes.inch15
                      ? parseFloat(
                          data.placementPrizes.inch15.replaceAll(",", ".")
                        )
                      : 0,
                    inch16: data.placementPrizes.inch16
                      ? parseFloat(
                          data.placementPrizes.inch16.replaceAll(",", ".")
                        )
                      : 0,
                    inch17: data.placementPrizes.inch17
                      ? parseFloat(
                          data.placementPrizes.inch17.replaceAll(",", ".")
                        )
                      : 0,
                    inch18: data.placementPrizes.inch18
                      ? parseFloat(
                          data.placementPrizes.inch18.replaceAll(",", ".")
                        )
                      : 0,
                    inch19: data.placementPrizes.inch19
                      ? parseFloat(
                          data.placementPrizes.inch19.replaceAll(",", ".")
                        )
                      : 0,
                    inch20: data.placementPrizes.inch20
                      ? parseFloat(
                          data.placementPrizes.inch20.replaceAll(",", ".")
                        )
                      : 0,
                    inch21: data.placementPrizes.inch21
                      ? parseFloat(
                          data.placementPrizes.inch21.replaceAll(",", ".")
                        )
                      : 0,
                    inch22: data.placementPrizes.inch22
                      ? parseFloat(
                          data.placementPrizes.inch22.replaceAll(",", ".")
                        )
                      : 0,
                    inch23: data.placementPrizes.inch23
                      ? parseFloat(
                          data.placementPrizes.inch23.replaceAll(",", ".")
                        )
                      : 0,
                    inch24: data.placementPrizes.inch24
                      ? parseFloat(
                          data.placementPrizes.inch24.replaceAll(",", ".")
                        )
                      : 0,
                  },
                  runFlatAmount: data.runFlatAmount
                    ? parseFloat(data.runFlatAmount.replaceAll(",", "."))
                    : 0,
                  mobile: mobile,
                },
                i18n.language
              );

              if (typeof result == "string") {
                setError(t("errorMsg"));

                return;
              }

              setRegistered(true);
            }}
          >
            <div style={{ maxWidth: "500px" }}>
              <h2>{t("register.generalInformation")}</h2>
              <label>{t("register.companyName")}*</label>
              <input
                value={data.businessName}
                onChange={e => {
                  setData({
                    ...data,
                    businessName: e.target.value,
                  });
                }}
                className={`input ${
                  data.businessName === "" ? "red-border" : null
                }`}
                type="text"
                placeholder={t("register.companyName")}
                required={true}
              />
              <label>{t("register.contactName")}*</label>
              <input
                value={data.name}
                onChange={e => {
                  setData({
                    ...data,
                    name: e.target.value,
                  });
                }}
                className={`input ${data.name === "" ? "red-border" : null}`}
                type="text"
                placeholder={t("register.contactName")}
                required={true}
              />
              <label>{t("login.email")}*</label>
              <input
                value={data.email}
                onChange={e => {
                  setData({
                    ...data,
                    email: e.target.value,
                  });
                }}
                className={`input ${data.email === "" ? "red-border" : null}`}
                type="email"
                placeholder={t("login.email")}
                required={true}
              />
              <label>{t("login.password")}*</label>
              <input
                value={data.password}
                onChange={e => {
                  setData({
                    ...data,
                    password: e.target.value,
                  });
                }}
                className={`input ${
                  data.password === "" ? "red-border" : null
                }`}
                type="password"
                placeholder={t("login.password")}
                required={true}
              />
              <label>{t("register.passwordRep")}*</label>
              <input
                value={confirmPassword}
                onChange={e => {
                  setConfirmPassword(e.target.value);
                }}
                className={`input ${
                  confirmPassword === "" ? "red-border" : null
                }`}
                type="password"
                placeholder={t("register.passwordRep")}
                required={true}
              />
              <label>{t("register.street")}*</label>
              <input
                value={data.street}
                onChange={e => {
                  setData({
                    ...data,
                    street: e.target.value,
                  });
                }}
                className={`input ${data.street === "" ? "red-border" : null}`}
                type="text"
                placeholder={t("register.street")}
                required={true}
              />
              <label>{t("register.number")}*</label>
              <input
                value={data.number}
                onChange={e => {
                  setData({
                    ...data,
                    number: e.target.value,
                  });
                }}
                className={`input ${data.number === "" ? "red-border" : null}`}
                type="text"
                placeholder={t("register.number")}
                required={true}
              />
              <label>{t("register.zipcode")}*</label>
              <input
                value={data.zipcode}
                onChange={e => {
                  setData({
                    ...data,
                    zipcode: e.target.value,
                  });
                }}
                className={`input ${data.zipcode === "" ? "red-border" : null}`}
                type="text"
                placeholder={t("register.zipcode")}
                required={true}
              />
              <label>{t("register.place")}*</label>
              <input
                value={data.city}
                onChange={e => {
                  setData({
                    ...data,
                    city: e.target.value,
                  });
                }}
                className={`input ${data.city === "" ? "red-border" : null}`}
                type="text"
                placeholder={t("register.place")}
                required={true}
              />
              <label>{t("register.country").toString()}*</label>
              <div className="mb-4">
                {isBE() ? "België" : null}
                {isFR() ? "Belgique" : null}
                {isNL() ? "Nederland" : null}
              </div>
              <label>{t("register.phone")}*</label>
              <input
                value={data.phone}
                onChange={e => {
                  setData({
                    ...data,
                    phone: e.target.value,
                  });
                }}
                className={`input ${data.phone === "" ? "red-border" : null}`}
                type="text"
                placeholder={t("register.phone")}
                required={true}
              />
              <label>IBAN</label>
              <input
                value={data.iban}
                onChange={e => {
                  setData({
                    ...data,
                    iban: e.target.value,
                  });
                }}
                className="input"
                type="text"
                placeholder="IBAN"
              />
              <label>{t("register.TAV")}*</label>
              <input
                value={data.vat}
                onChange={e => {
                  setData({
                    ...data,
                    vat: e.target.value,
                  });
                }}
                className={`input ${data.vat === "" ? "red-border" : null}`}
                type="text"
                placeholder={t("register.TAV")}
                required={true}
              />
              <div
                className="mb-4 pointer flex items-center"
                onClick={() => {
                  setShowExtra(!showExtra);
                }}
              >
                {t("more_options")}{" "}
                <div className="ml-3">
                  {showExtra ? <FaChevronUp /> : <FaChevronDown />}
                </div>
              </div>
            </div>
            {showExtra ? (
              <div
                className="flex"
                style={{ width: "100%", justifyContent: "space-between" }}
              >
                <div className="w-30">
                  <h2>{t("register.prices")}</h2>
                  <div className="flex justify-between">
                    <div className="w-45">
                      <label>{t("register.price10")}</label>
                      <div className="flex items-center">
                        <input
                          value={data.placementPrizes.inch10}
                          onChange={e => {
                            setData({
                              ...data,
                              placementPrizes: {
                                ...data.placementPrizes,
                                inch10: e.target.value,
                              },
                            });
                          }}
                          className="input"
                          type="text"
                          placeholder={t("register.price10")}
                        />
                        <div style={{ marginLeft: "-20px", marginTop: "-5px" }}>
                          <FaEuroSign />
                        </div>
                      </div>
                      <label>{t("register.price11")}</label>
                      <div className="flex items-center">
                        <input
                          value={data.placementPrizes.inch11}
                          onChange={e => {
                            setData({
                              ...data,
                              placementPrizes: {
                                ...data.placementPrizes,
                                inch11: e.target.value,
                              },
                            });
                          }}
                          className="input"
                          type="text"
                          placeholder={t("register.price11")}
                        />
                        <div style={{ marginLeft: "-20px", marginTop: "-5px" }}>
                          <FaEuroSign />
                        </div>
                      </div>
                      <label>{t("register.price12")}</label>
                      <div className="flex items-center">
                        <input
                          value={data.placementPrizes.inch12}
                          onChange={e => {
                            setData({
                              ...data,
                              placementPrizes: {
                                ...data.placementPrizes,
                                inch12: e.target.value,
                              },
                            });
                          }}
                          className="input"
                          type="text"
                          placeholder={t("register.price12")}
                        />
                        <div style={{ marginLeft: "-20px", marginTop: "-5px" }}>
                          <FaEuroSign />
                        </div>
                      </div>
                      <label>{t("register.price13")}</label>
                      <div className="flex items-center">
                        <input
                          value={data.placementPrizes.inch13}
                          onChange={e => {
                            setData({
                              ...data,
                              placementPrizes: {
                                ...data.placementPrizes,
                                inch13: e.target.value,
                              },
                            });
                          }}
                          className="input"
                          type="text"
                          placeholder={t("register.price13")}
                        />
                        <div style={{ marginLeft: "-20px", marginTop: "-5px" }}>
                          <FaEuroSign />
                        </div>
                      </div>
                      <label>{t("register.price14")}</label>
                      <div className="flex items-center">
                        <input
                          value={data.placementPrizes.inch14}
                          onChange={e => {
                            setData({
                              ...data,
                              placementPrizes: {
                                ...data.placementPrizes,
                                inch14: e.target.value,
                              },
                            });
                          }}
                          className="input"
                          type="text"
                          placeholder={t("register.price14")}
                        />
                        <div style={{ marginLeft: "-20px", marginTop: "-5px" }}>
                          <FaEuroSign />
                        </div>
                      </div>
                      <label>{t("register.price15")}</label>
                      <div className="flex items-center">
                        <input
                          value={data.placementPrizes.inch15}
                          onChange={e => {
                            setData({
                              ...data,
                              placementPrizes: {
                                ...data.placementPrizes,
                                inch15: e.target.value,
                              },
                            });
                          }}
                          className="input"
                          type="text"
                          placeholder={t("register.price15")}
                        />
                        <div style={{ marginLeft: "-20px", marginTop: "-5px" }}>
                          <FaEuroSign />
                        </div>
                      </div>
                      <label>{t("register.price16")}</label>
                      <div className="flex items-center">
                        <input
                          value={data.placementPrizes.inch16}
                          onChange={e => {
                            setData({
                              ...data,
                              placementPrizes: {
                                ...data.placementPrizes,
                                inch16: e.target.value,
                              },
                            });
                          }}
                          className="input"
                          type="text"
                          placeholder={t("register.price16")}
                        />
                        <div style={{ marginLeft: "-20px", marginTop: "-5px" }}>
                          <FaEuroSign />
                        </div>
                      </div>
                      <label>{t("register.price17")}</label>
                      <div className="flex items-center">
                        <input
                          value={data.placementPrizes.inch17}
                          onChange={e => {
                            setData({
                              ...data,
                              placementPrizes: {
                                ...data.placementPrizes,
                                inch17: e.target.value,
                              },
                            });
                          }}
                          className="input"
                          type="text"
                          placeholder={t("register.price17")}
                        />
                        <div style={{ marginLeft: "-20px", marginTop: "-5px" }}>
                          <FaEuroSign />
                        </div>
                      </div>
                    </div>
                    <div className="w-45">
                      <label>{t("register.price18")}</label>
                      <div className="flex items-center">
                        <input
                          value={data.placementPrizes.inch18}
                          onChange={e => {
                            setData({
                              ...data,
                              placementPrizes: {
                                ...data.placementPrizes,
                                inch18: e.target.value,
                              },
                            });
                          }}
                          className="input"
                          type="text"
                          placeholder={t("register.price18")}
                        />
                        <div style={{ marginLeft: "-20px", marginTop: "-5px" }}>
                          <FaEuroSign />
                        </div>
                      </div>
                      <label>{t("register.price19")}</label>
                      <div className="flex items-center">
                        <input
                          value={data.placementPrizes.inch19}
                          onChange={e => {
                            setData({
                              ...data,
                              placementPrizes: {
                                ...data.placementPrizes,
                                inch19: e.target.value,
                              },
                            });
                          }}
                          className="input"
                          type="text"
                          placeholder={t("register.price19")}
                        />
                        <div style={{ marginLeft: "-20px", marginTop: "-5px" }}>
                          <FaEuroSign />
                        </div>
                      </div>
                      <label>{t("register.price20")}</label>
                      <div className="flex items-center">
                        <input
                          value={data.placementPrizes.inch20}
                          onChange={e => {
                            setData({
                              ...data,
                              placementPrizes: {
                                ...data.placementPrizes,
                                inch20: e.target.value,
                              },
                            });
                          }}
                          className="input"
                          type="text"
                          placeholder={t("register.price20")}
                        />
                        <div style={{ marginLeft: "-20px", marginTop: "-5px" }}>
                          <FaEuroSign />
                        </div>
                      </div>
                      <label>{t("register.price21")}</label>
                      <div className="flex items-center">
                        <input
                          value={data.placementPrizes.inch21}
                          onChange={e => {
                            setData({
                              ...data,
                              placementPrizes: {
                                ...data.placementPrizes,
                                inch21: e.target.value,
                              },
                            });
                          }}
                          className="input"
                          type="text"
                          placeholder={t("register.price21")}
                        />
                        <div style={{ marginLeft: "-20px", marginTop: "-5px" }}>
                          <FaEuroSign />
                        </div>
                      </div>
                      <label>{t("register.price22")}</label>
                      <div className="flex items-center">
                        <input
                          value={data.placementPrizes.inch22}
                          onChange={e => {
                            setData({
                              ...data,
                              placementPrizes: {
                                ...data.placementPrizes,
                                inch22: e.target.value,
                              },
                            });
                          }}
                          className="input"
                          type="text"
                          placeholder={t("register.price22")}
                        />
                        <div style={{ marginLeft: "-20px", marginTop: "-5px" }}>
                          <FaEuroSign />
                        </div>
                      </div>
                      <label>{t("register.price23")}</label>
                      <div className="flex items-center">
                        <input
                          value={data.placementPrizes.inch23}
                          onChange={e => {
                            setData({
                              ...data,
                              placementPrizes: {
                                ...data.placementPrizes,
                                inch23: e.target.value,
                              },
                            });
                          }}
                          className="input"
                          type="text"
                          placeholder={t("register.price23")}
                        />
                        <div style={{ marginLeft: "-20px", marginTop: "-5px" }}>
                          <FaEuroSign />
                        </div>
                      </div>
                      <label>{t("register.price24")}</label>
                      <div className="flex items-center">
                        <input
                          value={data.placementPrizes.inch24}
                          onChange={e => {
                            setData({
                              ...data,
                              placementPrizes: {
                                ...data.placementPrizes,
                                inch24: e.target.value,
                              },
                            });
                          }}
                          className="input"
                          type="text"
                          placeholder={t("register.price24")}
                        />
                        <div style={{ marginLeft: "-20px", marginTop: "-5px" }}>
                          <FaEuroSign />
                        </div>
                      </div>
                      <Select
                        search={false}
                        title={t("register.runflat").toString()}
                        tooltip={false}
                        options={[
                          { label: t("register.yes"), value: "ja" },
                          { label: t("register.no"), value: "nee" },
                        ]}
                        selected={data.runFlat ? "ja" : "nee"}
                        selectOption={selected => {
                          setData({
                            ...data,
                            runFlat: selected === "ja",
                          });
                        }}
                      />
                      {data.runFlat ? (
                        <>
                          <label>{t("register.extraPriceRft")}</label>
                          <div className="flex items-center">
                            <input
                              value={data.runFlatAmount}
                              onChange={e => {
                                setData({
                                  ...data,
                                  runFlatAmount: e.target.value,
                                });
                              }}
                              className="input"
                              type="text"
                              placeholder={t("register.extraPriceRft")}
                              required={data.runFlat}
                            />
                            <div
                              style={{ marginLeft: "-20px", marginTop: "-5px" }}
                            >
                              <FaEuroSign />
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="w-30">
                  <h2>{t("register.openingHours")}</h2>
                  <table>
                    <tbody>
                      <tr>
                        <td />
                        <td>
                          <div className="flex">
                            <div className="mr-auto">Voormiddag van - tot</div>
                            <div>Namiddag van - tot</div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Ma</td>
                        <td>
                          <div className="flex items-center">
                            <input
                              className="input xxsmall"
                              type="text"
                              pattern="[0-9][0-9]:[0-9][0-9]"
                              onBlur={e => {
                                e.target.value = e.target.value.padStart(
                                  2,
                                  "0"
                                );
                                if (e.target.value.match("^[0-9][0-9]:?$")) {
                                  let newData = {
                                    ...data,
                                    startHourWeek:
                                      e.target.value +
                                      (e.target.value.includes(":")
                                        ? "00"
                                        : ":00"),
                                  };

                                  if (weekdaysSame) {
                                    newData["startHourTue"] =
                                      e.target.value +
                                      (e.target.value.includes(":")
                                        ? "00"
                                        : ":00");
                                    newData["startHourWed"] =
                                      e.target.value +
                                      (e.target.value.includes(":")
                                        ? "00"
                                        : ":00");
                                    newData["startHourThu"] =
                                      e.target.value +
                                      (e.target.value.includes(":")
                                        ? "00"
                                        : ":00");
                                    newData["startHourFri"] =
                                      e.target.value +
                                      (e.target.value.includes(":")
                                        ? "00"
                                        : ":00");
                                  }

                                  setData(newData);
                                }
                              }}
                              title="Gelieve het formaat hh:mm te volgen. Uren en minuten moeten altijd met 2 cijfers geschreven worden. Minuten moeten ook altijd geschreven worden. Bij een volledig uur, gelieve 00 te gebruiken bij de uren"
                              value={data.startHourWeek}
                              onChange={e => {
                                let newData = {
                                  ...data,
                                  startHourWeek: e.target.value,
                                };

                                if (weekdaysSame) {
                                  newData["startHourTue"] = e.target.value;
                                  newData["startHourWed"] = e.target.value;
                                  newData["startHourThu"] = e.target.value;
                                  newData["startHourFri"] = e.target.value;
                                }

                                setData(newData);
                              }}
                              placeholder={`hh:mm`}
                            />
                            <input
                              className="input xxsmall"
                              type="text"
                              pattern="[0-9][0-9]:[0-9][0-9]"
                              title="Gelieve het formaat hh:mm te volgen. Uren en minuten moeten altijd met 2 cijfers geschreven worden. Minuten moeten ook altijd geschreven worden. Bij een volledig uur, gelieve 00 te gebruiken bij de uren"
                              onBlur={e => {
                                e.target.value = e.target.value.padStart(
                                  2,
                                  "0"
                                );
                                if (e.target.value.match("^[0-9][0-9]:?$")) {
                                  let newData = {
                                    ...data,
                                    startHourWeekMid:
                                      e.target.value +
                                      (e.target.value.includes(":")
                                        ? "00"
                                        : ":00"),
                                  };

                                  if (weekdaysSame) {
                                    newData["startHourTueMid"] =
                                      e.target.value +
                                      (e.target.value.includes(":")
                                        ? "00"
                                        : ":00");
                                    newData["startHourWedMid"] =
                                      e.target.value +
                                      (e.target.value.includes(":")
                                        ? "00"
                                        : ":00");
                                    newData["startHourThuMid"] =
                                      e.target.value +
                                      (e.target.value.includes(":")
                                        ? "00"
                                        : ":00");
                                    newData["startHourFriMid"] =
                                      e.target.value +
                                      (e.target.value.includes(":")
                                        ? "00"
                                        : ":00");
                                  }

                                  setData(newData);
                                }
                              }}
                              value={data.startHourWeekMid}
                              onChange={e => {
                                let newData = {
                                  ...data,
                                  startHourWeekMid: e.target.value,
                                };

                                if (weekdaysSame) {
                                  newData["startHourTueMid"] = e.target.value;
                                  newData["startHourWedMid"] = e.target.value;
                                  newData["startHourThuMid"] = e.target.value;
                                  newData["startHourFriMid"] = e.target.value;
                                }

                                setData(newData);
                              }}
                              placeholder={`hh:mm`}
                            />
                            <div className="ml-4 mr-4">-</div>
                            <input
                              className="input xxsmall"
                              type="text"
                              pattern="[0-9][0-9]:[0-9][0-9]"
                              title="Gelieve het formaat hh:mm te volgen. Uren en minuten moeten altijd met 2 cijfers geschreven worden. Minuten moeten ook altijd geschreven worden. Bij een volledig uur, gelieve 00 te gebruiken bij de uren"
                              onBlur={e => {
                                e.target.value = e.target.value.padStart(
                                  2,
                                  "0"
                                );
                                if (e.target.value.match("^[0-9][0-9]:?$")) {
                                  let newData = {
                                    ...data,
                                    endHourWeekMid:
                                      e.target.value +
                                      (e.target.value.includes(":")
                                        ? "00"
                                        : ":00"),
                                  };

                                  if (weekdaysSame) {
                                    newData["endHourTueMid"] =
                                      e.target.value +
                                      (e.target.value.includes(":")
                                        ? "00"
                                        : ":00");
                                    newData["endHourWedMid"] =
                                      e.target.value +
                                      (e.target.value.includes(":")
                                        ? "00"
                                        : ":00");
                                    newData["endHourThuMid"] =
                                      e.target.value +
                                      (e.target.value.includes(":")
                                        ? "00"
                                        : ":00");
                                    newData["endHourFriMid"] =
                                      e.target.value +
                                      (e.target.value.includes(":")
                                        ? "00"
                                        : ":00");
                                  }

                                  setData(newData);
                                }
                              }}
                              value={data.endHourWeekMid}
                              onChange={e => {
                                let newData = {
                                  ...data,
                                  endHourWeekMid: e.target.value,
                                };

                                if (weekdaysSame) {
                                  newData["endHourTueMid"] = e.target.value;
                                  newData["endHourWedMid"] = e.target.value;
                                  newData["endHourThuMid"] = e.target.value;
                                  newData["endHourFriMid"] = e.target.value;
                                }

                                setData(newData);
                              }}
                              placeholder={`hh:mm`}
                            />
                            <input
                              className="input xxsmall"
                              type="text"
                              pattern="[0-9][0-9]:[0-9][0-9]"
                              title="Gelieve het formaat hh:mm te volgen. Uren en minuten moeten altijd met 2 cijfers geschreven worden. Minuten moeten ook altijd geschreven worden. Bij een volledig uur, gelieve 00 te gebruiken bij de uren"
                              onBlur={e => {
                                e.target.value = e.target.value.padStart(
                                  2,
                                  "0"
                                );
                                if (e.target.value.match("^[0-9][0-9]:?$")) {
                                  let newData = {
                                    ...data,
                                    endHourWeek:
                                      e.target.value +
                                      (e.target.value.includes(":")
                                        ? "00"
                                        : ":00"),
                                  };

                                  if (weekdaysSame) {
                                    newData["endHourTue"] =
                                      e.target.value +
                                      (e.target.value.includes(":")
                                        ? "00"
                                        : ":00");
                                    newData["endHourWed"] =
                                      e.target.value +
                                      (e.target.value.includes(":")
                                        ? "00"
                                        : ":00");
                                    newData["endHourThu"] =
                                      e.target.value +
                                      (e.target.value.includes(":")
                                        ? "00"
                                        : ":00");
                                    newData["endHourFri"] =
                                      e.target.value +
                                      (e.target.value.includes(":")
                                        ? "00"
                                        : ":00");
                                  }

                                  setData(newData);
                                }
                              }}
                              value={data.endHourWeek}
                              onChange={e => {
                                let newData = {
                                  ...data,
                                  endHourWeek: e.target.value,
                                };

                                if (weekdaysSame) {
                                  newData["endHourTue"] = e.target.value;
                                  newData["endHourWed"] = e.target.value;
                                  newData["endHourThu"] = e.target.value;
                                  newData["endHourFri"] = e.target.value;
                                }

                                setData(newData);
                              }}
                              placeholder={`hh:mm`}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td />
                        <td>
                          <Checkbox
                            title={"Dit geldt voor alle weekdagen"}
                            value={weekdaysSame}
                            onClick={checked => {
                              setWeekdaysSame(checked);

                              if (checked) {
                                let newData = {
                                  ...data,
                                };

                                newData["startHourTue"] = data.startHourWeek;
                                newData["startHourWed"] = data.startHourWeek;
                                newData["startHourThu"] = data.startHourWeek;
                                newData["startHourFri"] = data.startHourWeek;

                                newData["startHourTueMid"] =
                                  data.startHourWeekMid;
                                newData["startHourWedMid"] =
                                  data.startHourWeekMid;
                                newData["startHourThuMid"] =
                                  data.startHourWeekMid;
                                newData["startHourFriMid"] =
                                  data.startHourWeekMid;

                                newData["endHourTueMid"] = data.endHourWeekMid;
                                newData["endHourWedMid"] = data.endHourWeekMid;
                                newData["endHourThuMid"] = data.endHourWeekMid;
                                newData["endHourFriMid"] = data.endHourWeekMid;

                                newData["endHourTue"] = data.endHourWeek;
                                newData["endHourWed"] = data.endHourWeek;
                                newData["endHourThu"] = data.endHourWeek;
                                newData["endHourFri"] = data.endHourWeek;

                                setData(newData);
                              }
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Di</td>
                        <td>
                          <div className="flex items-center">
                            <input
                              className="input xxsmall"
                              type="text"
                              pattern="[0-9][0-9]:[0-9][0-9]"
                              title="Gelieve het formaat hh:mm te volgen. Uren en minuten moeten altijd met 2 cijfers geschreven worden. Minuten moeten ook altijd geschreven worden. Bij een volledig uur, gelieve 00 te gebruiken bij de uren"
                              onBlur={e => {
                                e.target.value = e.target.value.padStart(
                                  2,
                                  "0"
                                );
                                if (e.target.value.match("^[0-9][0-9]:?$")) {
                                  let newData = {
                                    ...data,
                                    startHourTue:
                                      e.target.value +
                                      (e.target.value.includes(":")
                                        ? "00"
                                        : ":00"),
                                  };

                                  setData(newData);
                                }
                              }}
                              value={data.startHourTue}
                              onChange={e => {
                                setData({
                                  ...data,
                                  startHourTue: e.target.value,
                                });
                              }}
                              placeholder={`hh:mm`}
                            />
                            <input
                              className="input xxsmall"
                              type="text"
                              pattern="[0-9][0-9]:[0-9][0-9]"
                              title="Gelieve het formaat hh:mm te volgen. Uren en minuten moeten altijd met 2 cijfers geschreven worden. Minuten moeten ook altijd geschreven worden. Bij een volledig uur, gelieve 00 te gebruiken bij de uren"
                              value={data.startHourTueMid}
                              onBlur={e => {
                                e.target.value = e.target.value.padStart(
                                  2,
                                  "0"
                                );
                                if (e.target.value.match("^[0-9][0-9]:?$")) {
                                  let newData = {
                                    ...data,
                                    startHourTueMid:
                                      e.target.value +
                                      (e.target.value.includes(":")
                                        ? "00"
                                        : ":00"),
                                  };

                                  setData(newData);
                                }
                              }}
                              onChange={e => {
                                setData({
                                  ...data,
                                  startHourTueMid: e.target.value,
                                });
                              }}
                              placeholder={`hh:mm`}
                            />
                            <div className="ml-4 mr-4">-</div>
                            <input
                              className="input xxsmall"
                              type="text"
                              pattern="[0-9][0-9]:[0-9][0-9]"
                              title="Gelieve het formaat hh:mm te volgen. Uren en minuten moeten altijd met 2 cijfers geschreven worden. Minuten moeten ook altijd geschreven worden. Bij een volledig uur, gelieve 00 te gebruiken bij de uren"
                              onBlur={e => {
                                e.target.value = e.target.value.padStart(
                                  2,
                                  "0"
                                );
                                if (e.target.value.match("^[0-9][0-9]:?$")) {
                                  let newData = {
                                    ...data,
                                    endHourTueMid:
                                      e.target.value +
                                      (e.target.value.includes(":")
                                        ? "00"
                                        : ":00"),
                                  };

                                  setData(newData);
                                }
                              }}
                              value={data.endHourTueMid}
                              onChange={e => {
                                setData({
                                  ...data,
                                  endHourTueMid: e.target.value,
                                });
                              }}
                              placeholder={`hh:mm`}
                            />
                            <input
                              className="input xxsmall"
                              type="text"
                              pattern="[0-9][0-9]:[0-9][0-9]"
                              title="Gelieve het formaat hh:mm te volgen. Uren en minuten moeten altijd met 2 cijfers geschreven worden. Minuten moeten ook altijd geschreven worden. Bij een volledig uur, gelieve 00 te gebruiken bij de uren"
                              onBlur={e => {
                                e.target.value = e.target.value.padStart(
                                  2,
                                  "0"
                                );
                                if (e.target.value.match("^[0-9][0-9]:?$")) {
                                  let newData = {
                                    ...data,
                                    endHourTue:
                                      e.target.value +
                                      (e.target.value.includes(":")
                                        ? "00"
                                        : ":00"),
                                  };

                                  setData(newData);
                                }
                              }}
                              value={data.endHourTue}
                              onChange={e => {
                                setData({
                                  ...data,
                                  endHourTue: e.target.value,
                                });
                              }}
                              placeholder={`hh:mm`}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Wo</td>
                        <td>
                          <div className="flex items-center">
                            <input
                              className="input xxsmall"
                              type="text"
                              pattern="[0-9][0-9]:[0-9][0-9]"
                              title="Gelieve het formaat hh:mm te volgen. Uren en minuten moeten altijd met 2 cijfers geschreven worden. Minuten moeten ook altijd geschreven worden. Bij een volledig uur, gelieve 00 te gebruiken bij de uren"
                              onBlur={e => {
                                e.target.value = e.target.value.padStart(
                                  2,
                                  "0"
                                );
                                if (e.target.value.match("^[0-9][0-9]:?$")) {
                                  let newData = {
                                    ...data,
                                    startHourWed:
                                      e.target.value +
                                      (e.target.value.includes(":")
                                        ? "00"
                                        : ":00"),
                                  };

                                  setData(newData);
                                }
                              }}
                              value={data.startHourWed}
                              onChange={e => {
                                setData({
                                  ...data,
                                  startHourWed: e.target.value,
                                });
                              }}
                              placeholder={`hh:mm`}
                            />
                            <input
                              className="input xxsmall"
                              type="text"
                              pattern="[0-9][0-9]:[0-9][0-9]"
                              title="Gelieve het formaat hh:mm te volgen. Uren en minuten moeten altijd met 2 cijfers geschreven worden. Minuten moeten ook altijd geschreven worden. Bij een volledig uur, gelieve 00 te gebruiken bij de uren"
                              onBlur={e => {
                                e.target.value = e.target.value.padStart(
                                  2,
                                  "0"
                                );
                                if (e.target.value.match("^[0-9][0-9]:?$")) {
                                  let newData = {
                                    ...data,
                                    startHourWedMid:
                                      e.target.value +
                                      (e.target.value.includes(":")
                                        ? "00"
                                        : ":00"),
                                  };

                                  setData(newData);
                                }
                              }}
                              value={data.startHourWedMid}
                              onChange={e => {
                                setData({
                                  ...data,
                                  startHourWedMid: e.target.value,
                                });
                              }}
                              placeholder={`hh:mm`}
                            />
                            <div className="ml-4 mr-4">-</div>
                            <input
                              className="input xxsmall"
                              type="text"
                              pattern="[0-9][0-9]:[0-9][0-9]"
                              title="Gelieve het formaat hh:mm te volgen. Uren en minuten moeten altijd met 2 cijfers geschreven worden. Minuten moeten ook altijd geschreven worden. Bij een volledig uur, gelieve 00 te gebruiken bij de uren"
                              onBlur={e => {
                                e.target.value = e.target.value.padStart(
                                  2,
                                  "0"
                                );
                                if (e.target.value.match("^[0-9][0-9]:?$")) {
                                  let newData = {
                                    ...data,
                                    endHourWedMid:
                                      e.target.value +
                                      (e.target.value.includes(":")
                                        ? "00"
                                        : ":00"),
                                  };

                                  setData(newData);
                                }
                              }}
                              value={data.endHourWedMid}
                              onChange={e => {
                                setData({
                                  ...data,
                                  endHourWedMid: e.target.value,
                                });
                              }}
                              placeholder={`hh:mm`}
                            />
                            <input
                              className="input xxsmall"
                              type="text"
                              pattern="[0-9][0-9]:[0-9][0-9]"
                              title="Gelieve het formaat hh:mm te volgen. Uren en minuten moeten altijd met 2 cijfers geschreven worden. Minuten moeten ook altijd geschreven worden. Bij een volledig uur, gelieve 00 te gebruiken bij de uren"
                              onBlur={e => {
                                e.target.value = e.target.value.padStart(
                                  2,
                                  "0"
                                );
                                if (e.target.value.match("^[0-9][0-9]:?$")) {
                                  let newData = {
                                    ...data,
                                    endHourWed:
                                      e.target.value +
                                      (e.target.value.includes(":")
                                        ? "00"
                                        : ":00"),
                                  };

                                  setData(newData);
                                }
                              }}
                              value={data.endHourWed}
                              onChange={e => {
                                setData({
                                  ...data,
                                  endHourWed: e.target.value,
                                });
                              }}
                              placeholder={`hh:mm`}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Do</td>
                        <td>
                          <div className="flex items-center">
                            <input
                              className="input xxsmall"
                              type="text"
                              pattern="[0-9][0-9]:[0-9][0-9]"
                              title="Gelieve het formaat hh:mm te volgen. Uren en minuten moeten altijd met 2 cijfers geschreven worden. Minuten moeten ook altijd geschreven worden. Bij een volledig uur, gelieve 00 te gebruiken bij de uren"
                              onBlur={e => {
                                e.target.value = e.target.value.padStart(
                                  2,
                                  "0"
                                );
                                if (e.target.value.match("^[0-9][0-9]:?$")) {
                                  let newData = {
                                    ...data,
                                    startHourThu:
                                      e.target.value +
                                      (e.target.value.includes(":")
                                        ? "00"
                                        : ":00"),
                                  };

                                  setData(newData);
                                }
                              }}
                              value={data.startHourThu}
                              onChange={e => {
                                setData({
                                  ...data,
                                  startHourThu: e.target.value,
                                });
                              }}
                              placeholder={`hh:mm`}
                            />
                            <input
                              className="input xxsmall"
                              type="text"
                              pattern="[0-9][0-9]:[0-9][0-9]"
                              title="Gelieve het formaat hh:mm te volgen. Uren en minuten moeten altijd met 2 cijfers geschreven worden. Minuten moeten ook altijd geschreven worden. Bij een volledig uur, gelieve 00 te gebruiken bij de uren"
                              onBlur={e => {
                                e.target.value = e.target.value.padStart(
                                  2,
                                  "0"
                                );
                                if (e.target.value.match("^[0-9][0-9]:?$")) {
                                  let newData = {
                                    ...data,
                                    startHourThuMid:
                                      e.target.value +
                                      (e.target.value.includes(":")
                                        ? "00"
                                        : ":00"),
                                  };

                                  setData(newData);
                                }
                              }}
                              value={data.startHourThuMid}
                              onChange={e => {
                                setData({
                                  ...data,
                                  startHourThuMid: e.target.value,
                                });
                              }}
                              placeholder={`hh:mm`}
                            />
                            <div className="ml-4 mr-4">-</div>
                            <input
                              className="input xxsmall"
                              type="text"
                              pattern="[0-9][0-9]:[0-9][0-9]"
                              title="Gelieve het formaat hh:mm te volgen. Uren en minuten moeten altijd met 2 cijfers geschreven worden. Minuten moeten ook altijd geschreven worden. Bij een volledig uur, gelieve 00 te gebruiken bij de uren"
                              onBlur={e => {
                                e.target.value = e.target.value.padStart(
                                  2,
                                  "0"
                                );
                                if (e.target.value.match("^[0-9][0-9]:?$")) {
                                  let newData = {
                                    ...data,
                                    endHourThuMid:
                                      e.target.value +
                                      (e.target.value.includes(":")
                                        ? "00"
                                        : ":00"),
                                  };

                                  setData(newData);
                                }
                              }}
                              value={data.endHourThuMid}
                              onChange={e => {
                                setData({
                                  ...data,
                                  endHourThuMid: e.target.value,
                                });
                              }}
                              placeholder={`hh:mm`}
                            />
                            <input
                              className="input xxsmall"
                              type="text"
                              pattern="[0-9][0-9]:[0-9][0-9]"
                              title="Gelieve het formaat hh:mm te volgen. Uren en minuten moeten altijd met 2 cijfers geschreven worden. Minuten moeten ook altijd geschreven worden. Bij een volledig uur, gelieve 00 te gebruiken bij de uren"
                              onBlur={e => {
                                e.target.value = e.target.value.padStart(
                                  2,
                                  "0"
                                );
                                if (e.target.value.match("^[0-9][0-9]:?$")) {
                                  let newData = {
                                    ...data,
                                    endHourThu:
                                      e.target.value +
                                      (e.target.value.includes(":")
                                        ? "00"
                                        : ":00"),
                                  };

                                  setData(newData);
                                }
                              }}
                              value={data.endHourThu}
                              onChange={e => {
                                setData({
                                  ...data,
                                  endHourThu: e.target.value,
                                });
                              }}
                              placeholder={`hh:mm`}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Vr</td>
                        <td>
                          <div className="flex items-center">
                            <input
                              className="input xxsmall"
                              type="text"
                              pattern="[0-9][0-9]:[0-9][0-9]"
                              title="Gelieve het formaat hh:mm te volgen. Uren en minuten moeten altijd met 2 cijfers geschreven worden. Minuten moeten ook altijd geschreven worden. Bij een volledig uur, gelieve 00 te gebruiken bij de uren"
                              onBlur={e => {
                                e.target.value = e.target.value.padStart(
                                  2,
                                  "0"
                                );
                                if (e.target.value.match("^[0-9][0-9]:?$")) {
                                  let newData = {
                                    ...data,
                                    startHourFri:
                                      e.target.value +
                                      (e.target.value.includes(":")
                                        ? "00"
                                        : ":00"),
                                  };

                                  setData(newData);
                                }
                              }}
                              value={data.startHourFri}
                              onChange={e => {
                                setData({
                                  ...data,
                                  startHourFri: e.target.value,
                                });
                              }}
                              placeholder={`hh:mm`}
                            />
                            <input
                              className="input xxsmall"
                              type="text"
                              pattern="[0-9][0-9]:[0-9][0-9]"
                              title="Gelieve het formaat hh:mm te volgen. Uren en minuten moeten altijd met 2 cijfers geschreven worden. Minuten moeten ook altijd geschreven worden. Bij een volledig uur, gelieve 00 te gebruiken bij de uren"
                              onBlur={e => {
                                e.target.value = e.target.value.padStart(
                                  2,
                                  "0"
                                );
                                if (e.target.value.match("^[0-9][0-9]:?$")) {
                                  let newData = {
                                    ...data,
                                    startHourFriMid:
                                      e.target.value +
                                      (e.target.value.includes(":")
                                        ? "00"
                                        : ":00"),
                                  };

                                  setData(newData);
                                }
                              }}
                              value={data.startHourFriMid}
                              onChange={e => {
                                setData({
                                  ...data,
                                  startHourFriMid: e.target.value,
                                });
                              }}
                              placeholder={`hh:mm`}
                            />
                            <div className="ml-4 mr-4">-</div>
                            <input
                              className="input xxsmall"
                              type="text"
                              pattern="[0-9][0-9]:[0-9][0-9]"
                              title="Gelieve het formaat hh:mm te volgen. Uren en minuten moeten altijd met 2 cijfers geschreven worden. Minuten moeten ook altijd geschreven worden. Bij een volledig uur, gelieve 00 te gebruiken bij de uren"
                              onBlur={e => {
                                e.target.value = e.target.value.padStart(
                                  2,
                                  "0"
                                );
                                if (e.target.value.match("^[0-9][0-9]:?$")) {
                                  let newData = {
                                    ...data,
                                    endHourFriMid:
                                      e.target.value +
                                      (e.target.value.includes(":")
                                        ? "00"
                                        : ":00"),
                                  };

                                  setData(newData);
                                }
                              }}
                              value={data.endHourFriMid}
                              onChange={e => {
                                setData({
                                  ...data,
                                  endHourFriMid: e.target.value,
                                });
                              }}
                              placeholder={`hh:mm`}
                            />
                            <input
                              className="input xxsmall"
                              type="text"
                              pattern="[0-9][0-9]:[0-9][0-9]"
                              title="Gelieve het formaat hh:mm te volgen. Uren en minuten moeten altijd met 2 cijfers geschreven worden. Minuten moeten ook altijd geschreven worden. Bij een volledig uur, gelieve 00 te gebruiken bij de uren"
                              onBlur={e => {
                                e.target.value = e.target.value.padStart(
                                  2,
                                  "0"
                                );
                                if (e.target.value.match("^[0-9][0-9]:?$")) {
                                  let newData = {
                                    ...data,
                                    endHourFri:
                                      e.target.value +
                                      (e.target.value.includes(":")
                                        ? "00"
                                        : ":00"),
                                  };

                                  setData(newData);
                                }
                              }}
                              value={data.endHourFri}
                              onChange={e => {
                                setData({
                                  ...data,
                                  endHourFri: e.target.value,
                                });
                              }}
                              placeholder={`hh:mm`}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Za</td>
                        <td>
                          <div className="flex items-center">
                            <input
                              className="input xxsmall"
                              type="text"
                              pattern="[0-9][0-9]:[0-9][0-9]"
                              title="Gelieve het formaat hh:mm te volgen. Uren en minuten moeten altijd met 2 cijfers geschreven worden. Minuten moeten ook altijd geschreven worden. Bij een volledig uur, gelieve 00 te gebruiken bij de uren"
                              onBlur={e => {
                                e.target.value = e.target.value.padStart(
                                  2,
                                  "0"
                                );
                                if (e.target.value.match("^[0-9][0-9]:?$")) {
                                  let newData = {
                                    ...data,
                                    startHourSaturday:
                                      e.target.value +
                                      (e.target.value.includes(":")
                                        ? "00"
                                        : ":00"),
                                  };

                                  setData(newData);
                                }
                              }}
                              value={data.startHourSaturday}
                              onChange={e => {
                                setData({
                                  ...data,
                                  startHourSaturday: e.target.value,
                                });
                              }}
                              placeholder={`hh:mm`}
                            />
                            <input
                              className="input xxsmall"
                              type="text"
                              pattern="[0-9][0-9]:[0-9][0-9]"
                              title="Gelieve het formaat hh:mm te volgen. Uren en minuten moeten altijd met 2 cijfers geschreven worden. Minuten moeten ook altijd geschreven worden. Bij een volledig uur, gelieve 00 te gebruiken bij de uren"
                              onBlur={e => {
                                e.target.value = e.target.value.padStart(
                                  2,
                                  "0"
                                );
                                if (e.target.value.match("^[0-9][0-9]:?$")) {
                                  let newData = {
                                    ...data,
                                    startHourSaturdayMid:
                                      e.target.value +
                                      (e.target.value.includes(":")
                                        ? "00"
                                        : ":00"),
                                  };

                                  setData(newData);
                                }
                              }}
                              value={data.startHourSaturdayMid}
                              onChange={e => {
                                setData({
                                  ...data,
                                  startHourSaturdayMid: e.target.value,
                                });
                              }}
                              placeholder={`hh:mm`}
                            />
                            <div className="ml-4 mr-4">-</div>
                            <input
                              className="input xxsmall"
                              type="text"
                              pattern="[0-9][0-9]:[0-9][0-9]"
                              title="Gelieve het formaat hh:mm te volgen. Uren en minuten moeten altijd met 2 cijfers geschreven worden. Minuten moeten ook altijd geschreven worden. Bij een volledig uur, gelieve 00 te gebruiken bij de uren"
                              onBlur={e => {
                                e.target.value = e.target.value.padStart(
                                  2,
                                  "0"
                                );
                                if (e.target.value.match("^[0-9][0-9]:?$")) {
                                  let newData = {
                                    ...data,
                                    endHourSaturdayMid:
                                      e.target.value +
                                      (e.target.value.includes(":")
                                        ? "00"
                                        : ":00"),
                                  };

                                  setData(newData);
                                }
                              }}
                              value={data.endHourSaturdayMid}
                              onChange={e => {
                                setData({
                                  ...data,
                                  endHourSaturdayMid: e.target.value,
                                });
                              }}
                              placeholder={`hh:mm`}
                            />
                            <input
                              className="input xxsmall"
                              type="text"
                              pattern="[0-9][0-9]:[0-9][0-9]"
                              title="Gelieve het formaat hh:mm te volgen. Uren en minuten moeten altijd met 2 cijfers geschreven worden. Minuten moeten ook altijd geschreven worden. Bij een volledig uur, gelieve 00 te gebruiken bij de uren"
                              onBlur={e => {
                                e.target.value = e.target.value.padStart(
                                  2,
                                  "0"
                                );
                                if (e.target.value.match("^[0-9][0-9]:?$")) {
                                  let newData = {
                                    ...data,
                                    endHourSaturday:
                                      e.target.value +
                                      (e.target.value.includes(":")
                                        ? "00"
                                        : ":00"),
                                  };

                                  setData(newData);
                                }
                              }}
                              value={data.endHourSaturday}
                              onChange={e => {
                                setData({
                                  ...data,
                                  endHourSaturday: e.target.value,
                                });
                              }}
                              placeholder={`hh:mm`}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Zo</td>
                        <td>
                          <div className="flex items-center">
                            <input
                              className="input xxsmall"
                              type="text"
                              pattern="[0-9][0-9]:[0-9][0-9]"
                              title="Gelieve het formaat hh:mm te volgen. Uren en minuten moeten altijd met 2 cijfers geschreven worden. Minuten moeten ook altijd geschreven worden. Bij een volledig uur, gelieve 00 te gebruiken bij de uren"
                              onBlur={e => {
                                e.target.value = e.target.value.padStart(
                                  2,
                                  "0"
                                );
                                if (e.target.value.match("^[0-9][0-9]:?$")) {
                                  let newData = {
                                    ...data,
                                    startHourSunday:
                                      e.target.value +
                                      (e.target.value.includes(":")
                                        ? "00"
                                        : ":00"),
                                  };

                                  setData(newData);
                                }
                              }}
                              value={data.startHourSunday}
                              onChange={e => {
                                setData({
                                  ...data,
                                  startHourSunday: e.target.value,
                                });
                              }}
                              placeholder={`hh:mm`}
                            />
                            <input
                              className="input xxsmall"
                              type="text"
                              pattern="[0-9][0-9]:[0-9][0-9]"
                              title="Gelieve het formaat hh:mm te volgen. Uren en minuten moeten altijd met 2 cijfers geschreven worden. Minuten moeten ook altijd geschreven worden. Bij een volledig uur, gelieve 00 te gebruiken bij de uren"
                              onBlur={e => {
                                e.target.value = e.target.value.padStart(
                                  2,
                                  "0"
                                );
                                if (e.target.value.match("^[0-9][0-9]:?$")) {
                                  let newData = {
                                    ...data,
                                    startHourSundayMid:
                                      e.target.value +
                                      (e.target.value.includes(":")
                                        ? "00"
                                        : ":00"),
                                  };

                                  setData(newData);
                                }
                              }}
                              value={data.startHourSundayMid}
                              onChange={e => {
                                setData({
                                  ...data,
                                  startHourSundayMid: e.target.value,
                                });
                              }}
                              placeholder={`hh:mm`}
                            />
                            <div className="ml-4 mr-4">-</div>
                            <input
                              className="input xxsmall"
                              type="text"
                              pattern="[0-9][0-9]:[0-9][0-9]"
                              title="Gelieve het formaat hh:mm te volgen. Uren en minuten moeten altijd met 2 cijfers geschreven worden. Minuten moeten ook altijd geschreven worden. Bij een volledig uur, gelieve 00 te gebruiken bij de uren"
                              onBlur={e => {
                                e.target.value = e.target.value.padStart(
                                  2,
                                  "0"
                                );
                                if (e.target.value.match("^[0-9][0-9]:?$")) {
                                  let newData = {
                                    ...data,
                                    endHourSundayMid:
                                      e.target.value +
                                      (e.target.value.includes(":")
                                        ? "00"
                                        : ":00"),
                                  };

                                  setData(newData);
                                }
                              }}
                              value={data.endHourSundayMid}
                              onChange={e => {
                                setData({
                                  ...data,
                                  endHourSundayMid: e.target.value,
                                });
                              }}
                              placeholder={`hh:mm`}
                            />
                            <input
                              className="input xxsmall"
                              type="text"
                              pattern="[0-9][0-9]:[0-9][0-9]"
                              title="Gelieve het formaat hh:mm te volgen. Uren en minuten moeten altijd met 2 cijfers geschreven worden. Minuten moeten ook altijd geschreven worden. Bij een volledig uur, gelieve 00 te gebruiken bij de uren"
                              onBlur={e => {
                                e.target.value = e.target.value.padStart(
                                  2,
                                  "0"
                                );
                                if (e.target.value.match("^[0-9][0-9]:?$")) {
                                  let newData = {
                                    ...data,
                                    endHourSunday:
                                      e.target.value +
                                      (e.target.value.includes(":")
                                        ? "00"
                                        : ":00"),
                                  };

                                  setData(newData);
                                }
                              }}
                              value={data.endHourSunday}
                              onChange={e => {
                                setData({
                                  ...data,
                                  endHourSunday: e.target.value,
                                });
                              }}
                              placeholder={`hh:mm`}
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="w-30">
                  <h2>{t("register.extraServices.title")}</h2>
                  <Checkbox
                    title={t("register.extraServices.extraService1")}
                    value={data.tyreStorage}
                    onClick={clicked => {
                      setData({
                        ...data,
                        tyreStorage: clicked,
                      });
                    }}
                  />
                  <Checkbox
                    title={t("register.extraServices.extraService2")}
                    value={data.rimCleaning}
                    onClick={clicked => {
                      setData({
                        ...data,
                        rimCleaning: clicked,
                      });
                    }}
                  />
                  <Checkbox
                    title={t("register.extraServices.extraService3")}
                    value={data.aligning}
                    onClick={clicked => {
                      setData({
                        ...data,
                        aligning: clicked,
                      });
                    }}
                  />
                  <Checkbox
                    title={t("register.extraServices.extraService4")}
                    value={data.fillNitrogen}
                    onClick={clicked => {
                      setData({
                        ...data,
                        fillNitrogen: clicked,
                      });
                    }}
                  />
                </div>
              </div>
            ) : null}
            {data.password !== confirmPassword ? (
              <div className="red mb-4">{t("register.passwordNoMatch")}</div>
            ) : null}
            {error !== "" ? <div className="red mb-4">{error}</div> : null}
            <Checkbox
              title={t("register.mobileService")}
              value={mobile}
              onClick={setMobile}
            />
            <Checkbox
              title={t("register.agreeToTerms")}
              value={terms}
              onClick={setTerms}
            />
            <button
              className="button mb-4"
              disabled={confirmPassword !== data.password}
            >
              {t("register.btn")}
            </button>
          </form>
        </div>
      )}
    </>
  );
}
