import React, { useEffect, useState } from "react";
import { AdminHeader } from "./AdminHeader";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  addImage,
  Article,
  createArticle,
  getArticles,
  updateArticle,
} from "./api/ArticleApi";
import { useEffectOnce } from "react-use";
import { Redirect, useParams } from "react-router-dom";
import slug from "./slug";

export function AdminBlogEditor() {
  const [article, setArticle] = useState<Article>({
    id: "",
    title: "",
    slug: "",
    author: "",
    text: "",
    image: "",
  });
  const [articles, setArticles] = useState<Article[]>([]);
  const [settingsSavedMessage, setSettingsSavedMessage] = useState<null | {
    success: boolean;
    message: string;
  }>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>();
  const [redirect, setRedirect] = useState(false);

  const { articleId } = useParams<{ articleId: string | undefined }>();

  useEffectOnce(() => {
    getArticles().then(result => {
      setArticles(result);
    });
  });

  useEffect(() => {
    if (!articleId) {
    }

    const existingArticle = articles.find(a => a.id === articleId);

    if (!existingArticle) {
      return;
    }

    setArticle(existingArticle);
  }, [articles, articleId]);

  if (redirect) {
    return <Redirect to={"/admin/blog"} />;
  }

  return (
    <>
      <AdminHeader />
      <div className="admin-dashboard">
        {window.location.href.includes("new") ? (
          <h1>Nieuwe blogpost</h1>
        ) : (
          <h1>Blogpost aanpassen</h1>
        )}
        <div className="w-45">
          <form
            onSubmit={async e => {
              e.preventDefault();

              let image;
              let imagePath;
              if (selectedFile) {
                image = await addImage(selectedFile);
              }

              if (image && typeof image !== "string" && image.path) {
                imagePath = image.path;
              }

              if (articleId !== "new") {
                try {
                  await updateArticle(
                    article.id,
                    article.title,
                    article.slug,
                    article.author,
                    article.text,
                    imagePath ? imagePath : article.image
                  );
                  setSettingsSavedMessage({
                    success: true,
                    message: "Blogpost is bijgewerkt.",
                  });
                  setTimeout(() => {
                    setRedirect(true);
                  }, 1000 * 5);
                } catch (e) {
                  setSettingsSavedMessage({
                    success: false,
                    message: "Er is iets fout gelopen.",
                  });
                }

                setTimeout(() => {
                  setSettingsSavedMessage(null);
                }, 1000 * 5);
              } else if (article) {
                try {
                  await createArticle(
                    article.title,
                    article.slug,
                    article.author,
                    article.text,
                    imagePath ? imagePath : article.image
                  );
                  setSettingsSavedMessage({
                    success: true,
                    message: "Blogpost is opgeslagen.",
                  });
                  setTimeout(() => {
                    setRedirect(true);
                  }, 1000 * 5);
                } catch (e) {
                  setSettingsSavedMessage({
                    success: false,
                    message: "Er is iets fout gelopen.",
                  });
                }

                setTimeout(() => {
                  setSettingsSavedMessage(null);
                }, 1000 * 5);
              }
            }}
          >
            <label>Titel</label>
            <input
              className="input"
              type="text"
              placeholder="Titel"
              required={true}
              value={article.title}
              onChange={e => {
                if (article) {
                  setArticle({
                    ...article,
                    title: e.target.value,
                  });
                }
              }}
            />

            <div className="flex justify-between">
              <label>Slug</label>
              <span>
                <b>Voorbeeld slug</b>: {slug(article.title)}
              </span>
            </div>

            <input
              className="input"
              type="text"
              placeholder="slug"
              required={true}
              value={article.slug}
              onChange={e => {
                if (article) {
                  setArticle({
                    ...article,
                    slug: e.target.value,
                  });
                }
              }}
            />

            <label>Auteur</label>
            <input
              className="input"
              type="text"
              placeholder="Auteur"
              required={true}
              value={article.author}
              onChange={e => {
                if (article) {
                  setArticle({
                    ...article,
                    author: e.target.value,
                  });
                }
              }}
            />

            <ReactQuill
              theme="snow"
              value={article?.text}
              onChange={value => {
                if (article) {
                  setArticle({
                    ...article,
                    text: value,
                  });
                }
              }}
            />

            <label>Afbeelding</label>
            <input
              type="file"
              required={articleId === "new"}
              onChange={e => {
                if (e.target.files && e.target.files.length > 0) {
                  setSelectedFile(e.target.files[0]);
                }
              }}
            />

            {settingsSavedMessage ? (
              <div className={`${settingsSavedMessage.success ? "" : "red"}`}>
                {settingsSavedMessage.message}
              </div>
            ) : null}
            <button className="button">Opslaan</button>
          </form>
        </div>
      </div>
    </>
  );
}
