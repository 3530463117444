import { API_ENDPOINT, getHeaders } from "./AuthApi";
import { DateTime } from "luxon";
import { getCountryParam } from "../Utils";

export type Tyre = {
  id: string;
  ean: string;
  name: string;
  description: string | null;
  height: string;
  width: string;
  radius: number;
  season: string;
  type: string;
  brandId: string;
  loadIndex: string;
  speedIndex: string;
  stock: number;
  priceBC: number;
  priceGarage: number;
  priceGarage12: number;
  supplierCode: string;
  fuelEfficiency: string;
  wetRoadEfficiency: string;
  noiseLevelDb: number;
  noiseIntensity: number;
  noiseClass: string;
  runFlat: boolean;
  eprel: string;
  topSale: boolean;
  webPromo: boolean;
  environmentCategory: string;
  image: string | null;
  descriptionEn: string | null;
  descriptionFr: string | null;
};

export type TyreToWatch = {
  tyreId: string;
  id: string;
  ean: string;
  name: string;
  description: string | null;
  height: string;
  width: string;
  radius: number;
  season: string;
  type: string;
  brandId: string;
  loadIndex: string;
  speedIndex: string;
  stock: number;
  priceBC: number;
  priceGarage: number;
  priceGarage12: number;
  supplierCode: string;
  tyreToWatchId: string;
  competitorPrizes: number[];
  competitorLogos: string[];
  fuelEfficiency: string;
  wetRoadEfficiency: string;
  noiseLevelDb: number;
  noiseIntensity: number;
  noiseClass: string;
  runFlat: boolean;
  eprel: string;
  topSale: boolean;
  webPromo: boolean;
  environmentCategory: string;
  image: string | null;
  country: string;
  descriptionEn: string | null;
  descriptionFr: string | null;
};

export type SearchOptions = {
  height: string[];
  width: string[];
  diameter: string[];
  season: string[];
  vehicleType: string[];
  brand: string[];
  loadIndex: string[];
  speedIndex: string[];
  fuelEfficiency: string[];
  wetRoadEfficiency: string[];
};

export async function getSearchOptions(search: string): Promise<SearchOptions> {
  const result = await fetch(
    `${API_ENDPOINT}/tyres/options?${search}&country=${getCountryParam()}`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  ).then(r => r.json());

  if (!result.options) {
    return {
      height: [],
      width: [],
      diameter: [],
      season: [],
      vehicleType: [],
      brand: [],
      loadIndex: [],
      speedIndex: [],
      fuelEfficiency: [],
      wetRoadEfficiency: [],
    };
  }

  return result.options;
}

export async function getTyres(
  page: number,
  search: string
): Promise<{ tyres: Tyre[]; next: boolean }> {
  const params = new URLSearchParams(search);
  params.set("page", page.toString());
  params.set("country", getCountryParam());

  const result = await fetch(`${API_ENDPOINT}/tyres?${params.toString()}`, {
    method: "GET",
    headers: getHeaders(),
  }).then(r => r.json());

  if (!result.tyres) {
    return { tyres: [], next: false };
  }

  return result;
}

export async function getTopSale(): Promise<Tyre[]> {
  const result = await fetch(
    `${API_ENDPOINT}/tyres/sale?country=${getCountryParam()}`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  ).then(r => r.json());

  if (!result.tyres) {
    return [];
  }

  return result.tyres;
}

export async function getWebPromo(): Promise<Tyre[]> {
  const result = await fetch(
    `${API_ENDPOINT}/tyres/promo?country=${getCountryParam()}`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  ).then(r => r.json());

  if (!result.tyres) {
    return [];
  }

  return result.tyres;
}

export async function getTyre(productId: string): Promise<Tyre> {
  const result = await fetch(
    `${API_ENDPOINT}/tyres/${productId}?country=${getCountryParam()}`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  ).then(r => r.json());

  return result.tyre;
}

export async function getTyreByEan(ean: string): Promise<Tyre> {
  const result = await fetch(
    `${API_ENDPOINT}/tyres/ean/${ean}?country=${getCountryParam()}`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  ).then(r => r.json());

  return result.tyre;
}

export async function getTyreById(id: string): Promise<Tyre> {
  const result = await fetch(
    `${API_ENDPOINT}/tyres/${id}?country=${getCountryParam()}`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  ).then(r => r.json());

  return result.tyre;
}

export async function getTyresToWatch(): Promise<TyreToWatch[]> {
  const result = await fetch(
    `${API_ENDPOINT}/tyresToWatch?country=${getCountryParam()}`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  ).then(r => r.json());

  if (!result.tyresToWatch) {
    return [];
  }

  return result.tyresToWatch;
}

export async function saveTyreToWatch(tyre: TyreToWatch): Promise<void> {
  await fetch(`${API_ENDPOINT}/tyresToWatch`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(tyre),
  }).then(r => r.json());
}

export async function getLatestSync(): Promise<DateTime> {
  const result = await fetch(`${API_ENDPOINT}/sync-status`, {
    method: "GET",
    headers: getHeaders(),
  }).then(r => r.json());

  return DateTime.fromISO(result.date);
}

export async function deleteTyreToWatch(tyre: TyreToWatch): Promise<void> {
  await fetch(`${API_ENDPOINT}/tyresToWatch/${tyre.tyreToWatchId}`, {
    method: "DELETE",
    headers: getHeaders(),
  }).then(r => r.json());
}

export async function deleteAllTyreToWatch(): Promise<void> {
  await fetch(`${API_ENDPOINT}/tyresToWatch`, {
    method: "DELETE",
    headers: getHeaders(),
  }).then(r => r.json());
}
