import React, { Dispatch, SetStateAction } from "react";
import _ from "lodash";

export function Pagination({
  itemsCount,
  pageSize,
  currentPage,
  updatePage,
}: {
  itemsCount: number;
  pageSize: number;
  currentPage: number;
  updatePage: Dispatch<SetStateAction<number>>;
}) {
  const pageCount = Math.ceil(itemsCount / pageSize);
  if (pageCount === 1) return null;
  const pages = _.range(1, _.min([pageCount + 1, 6]));

  return (
    <div className="pagination">
      <ul>
        {pages.map((page: number) => (
          <li key={page}>
            <button
              className={page === currentPage ? "active" : "not-active"}
              onClick={() => updatePage(page)}
            />
          </li>
        ))}
      </ul>
    </div>
  );
}
