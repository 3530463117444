import { API_ENDPOINT, getHeaders } from "./AuthApi";

export type Article = {
  id: string;
  title: string;
  slug: string;
  author: string;
  text: string;
  image: string;
};

export async function createArticle(
  title: string,
  slug: string,
  author: string,
  text: string,
  image: string
): Promise<Article | undefined> {
  const result = await fetch(`${API_ENDPOINT}/articles`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      title,
      slug,
      author,
      text,
      image,
    }),
  }).then(r => r.json());

  if (!result.article) {
    return undefined;
  }

  return result.article;
}

export async function getArticles(): Promise<Article[]> {
  const result = await fetch(`${API_ENDPOINT}/articles`, {
    method: "GET",
    headers: getHeaders(),
  }).then(r => r.json());

  if (!result.articles) {
    return [];
  }

  return result.articles;
}

export async function getArticle(
  articleId: string
): Promise<Article | undefined> {
  const result = await fetch(`${API_ENDPOINT}/articles/${articleId}`, {
    method: "GET",
    headers: getHeaders(),
  }).then(r => r.json());

  if (!result.article) {
    return undefined;
  }

  return result.article;
}

export async function getArticleBySlug(
  articleSlug: string
): Promise<Article | undefined> {
  const result = await fetch(`${API_ENDPOINT}/articles/slug/${articleSlug}`, {
    method: "GET",
    headers: getHeaders(),
  }).then(r => r.json());

  if (!result.article) {
    return undefined;
  }

  return result.article;
}

export async function updateArticle(
  articleId: string,
  title: string,
  slug: string,
  author: string,
  text: string,
  image: string
): Promise<Article | undefined> {
  const result = await fetch(`${API_ENDPOINT}/articles/${articleId}`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      id: articleId,
      title,
      slug,
      author,
      text,
      image,
    }),
  }).then(r => r.json());

  if (!result.article) {
    return undefined;
  }

  return result.article;
}

export async function deleteArticle(articleId: string): Promise<void> {
  await fetch(`${API_ENDPOINT}/articles/${articleId}`, {
    method: "DELETE",
    headers: getHeaders(),
  }).then(r => r.json());
}

export async function addImage(file: File): Promise<{ path: string } | string> {
  const formData = new FormData();
  formData.append("image", file);

  const result = await fetch(`${API_ENDPOINT}/upload`, {
    method: "POST",
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
    body: formData,
  }).then(r => r.json());

  if (result.message) {
    return result.message;
  }

  return result.image;
}
