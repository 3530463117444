import { API_ENDPOINT, getHeaders } from "./AuthApi";

export type PaymentMethod = {
  id: string;
  name: string;
  costFixed: number;
  costPercentage: number;
  country: string;
};

export async function listMethodsByCountry(
  country: string
): Promise<PaymentMethod[]> {
  const result = await fetch(`${API_ENDPOINT}/methods?country=${country}`, {
    method: "GET",
    headers: getHeaders(),
  }).then(r => r.json());

  if (!result.methods) {
    return [];
  }

  return result.methods;
}

export async function createPaymentMethods(
  items: {
    name: string;
    costFixed: number;
    costPercentage: number;
    country: string;
  }[]
): Promise<PaymentMethod[]> {
  const result = await fetch(`${API_ENDPOINT}/methods`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      methods: items,
    }),
  }).then(r => r.json());

  if (!result.methods) {
    return [];
  }

  return result.methods;
}
