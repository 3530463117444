import React, { useState } from "react";
import "./PartnerLogin.css";
import { login } from "./api/AuthApi";
import { Link, Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

export function PartnerLogin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [message, setMessage] = useState<null | string>(null);

  const { t } = useTranslation("partnerLogin");

  if (redirect || localStorage.getItem("token")) {
    return <Redirect push={true} to="/dashboard" />;
  }

  return (
    <div className="partner-container flex items-center justify-center">
      <Helmet>
        <title>{t("login.documentTitle")}</title>
        <meta name="description" content={t("login.meta")} />
      </Helmet>
      <div className="partner-wrapper">
        <h1 className="green">{t("title")}</h1>
        <div dangerouslySetInnerHTML={{ __html: t("intro") }}></div>

        <Link to="partner-register" className="button w-fit">
          {t("registerBtn")}
        </Link>
      </div>
      <div className="partner-wrapper margin">
        <h2>{t("login.title")}</h2>
        <form
          id="partnerLogin"
          onSubmit={async e => {
            e.preventDefault();

            const result = await login(email, password);

            if (typeof result == "string") {
              setMessage(t("login.wrong"));
              return;
            }

            setRedirect(true);
          }}
        >
          <label>{t("login.email")}</label>
          <input
            value={email}
            onChange={e => {
              setEmail(e.target.value);
            }}
            className="input"
            type="email"
            placeholder={t("login.email")}
            required={true}
          />
          <label>{t("login.password")}</label>
          <input
            value={password}
            onChange={e => {
              setPassword(e.target.value);
            }}
            className="input"
            type="password"
            placeholder={t("login.password")}
            required={true}
          />
          {message ? <div className="mb-4">{message}</div> : null}
          <button className="button w-fit" type="submit">
            {t("login.btn")}
          </button>
          <Link to="/auth/password-forgot" className="ml-5 text-button w-fit">
            {t("login.forget")}
          </Link>
        </form>
      </div>
    </div>
  );
}
