import ReactModal from "react-modal";
import React, { PropsWithChildren } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import "./Dialog.css";

export function Dialog({
  isOpen,
  children,
  onRequestClose,
  title,
  size,
  padding,
  shouldCloseOnOverlayClick = true,
  shouldCloseOnEsc = true,
}: PropsWithChildren<{
  isOpen: boolean;
  onRequestClose: () => void;
  shouldCloseOnOverlayClick?: boolean;
  shouldCloseOnEsc?: boolean;
  title: string;
  size: "small" | "wide" | "xsmall" | "large";
  padding: boolean;
}>) {
  const classNames = ["dialog-body"];
  if (size === "small") {
    classNames.push("is-small");
  }
  if (size === "wide") {
    classNames.push("is-wide");
  }
  if (size === "xsmall") {
    classNames.push("is-xsmall");
  }
  if (size === "large") {
    classNames.push("is-large");
  }
  if (padding) {
    classNames.push("p-8");
  }

  return (
    <ReactModal
      isOpen={isOpen}
      appElement={window.document.getElementById("root") as HTMLElement}
      onRequestClose={onRequestClose}
      className={classNames.join(" ")}
      overlayClassName="dialog-overlay"
      bodyOpenClassName="dialog-open"
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      shouldCloseOnEsc={shouldCloseOnEsc}
    >
      <div className={"dialog-header " + (padding ? "mb-4" : "p-8")}>
        <div className="flex items-center">
          <p className="dialog-title">{title}</p>
        </div>
        <div
          className="close-button ml-auto"
          title="Close dialog"
          onClick={e => {
            onRequestClose();
          }}
        >
          <FontAwesomeIcon icon={faTimes} size={"2x"} />
        </div>
      </div>
      {isOpen ? children : null}
    </ReactModal>
  );
}
