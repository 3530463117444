import { API_ENDPOINT, getHeaders } from "./AuthApi";
import { Tyre } from "./TyreApi";
import { request } from "http";

export type Brand = {
  id: string;
  name: string;
  logo: string;
  description: string;
  categoryId: string;
};

export async function getBrands(): Promise<Brand[]> {
  const result = await fetch(`${API_ENDPOINT}/brands`, {
    method: "GET",
    headers: getHeaders(),
  }).then(r => r.json());

  if (!result.brands) {
    return [];
  }

  return result.brands;
}

export async function getBrand(brandId: string): Promise<Brand | undefined> {
  const result = await fetch(`${API_ENDPOINT}/brands/${brandId}`, {
    method: "GET",
    headers: getHeaders(),
  }).then(r => r.json());

  if (!result.brand) {
    return undefined;
  }

  return result.brand;
}

export async function updateBrand(
  brandId: string,
  description: string
): Promise<Brand | undefined> {
  const result = await fetch(`${API_ENDPOINT}/brands/${brandId}`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      id: brandId,
      description: description,
    }),
  }).then(r => r.json());

  if (!result.brand) {
    return undefined;
  }

  return result.brand;
}
