import React, { useState } from "react";
import { AdminHeader } from "./AdminHeader";
import { Select } from "./Select";
import { useEffectOnce } from "react-use";
import { Brand, getBrand, getBrands, updateBrand } from "./api/BrandApi";
import { capitalize, compareOptions } from "./Utils";
import { Helmet } from "react-helmet";

export function AdminTextEditor({
  brands,
  setBrands,
}: {
  brands: Brand[];
  setBrands: (brands: Brand[]) => void;
}) {
  const [editBrand, setEditBrand] = useState<Brand>();
  const [settingsSavedMessage, setSettingsSavedMessage] = useState<null | {
    success: boolean;
    message: string;
  }>(null);

  const brandOptions = [{ label: "", value: "" }].concat(
    brands
      .filter(filter => filter.categoryId !== null)
      .map(brand => {
        return { label: capitalize(brand.name), value: brand.id };
      })
  );
  brandOptions.sort(compareOptions);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Text editor | Admin</title>
      </Helmet>
      <AdminHeader />
      <div className="admin-dashboard">
        <div className="w-45">
          <h1>Editor</h1>
          <h2>Merkteksten</h2>
          <form
            onSubmit={async e => {
              e.preventDefault();

              if (editBrand) {
                try {
                  await updateBrand(editBrand.id, editBrand.description);
                  setBrands(
                    brands.map(brand => {
                      if (brand.id === editBrand.id) {
                        return editBrand;
                      }
                      return brand;
                    })
                  );
                  setSettingsSavedMessage({
                    success: true,
                    message: "Gegevens opgeslagen",
                  });
                } catch (e) {
                  setSettingsSavedMessage({
                    success: false,
                    message: "Er is iets fout gelopen",
                  });
                }
                setTimeout(() => {
                  setSettingsSavedMessage(null);
                }, 1000 * 5);
              }
            }}
          >
            <Select
              title={"Kies merk"}
              tooltip={false}
              options={brandOptions}
              selected={editBrand?.id || ""}
              selectOption={selected => {
                const existingBrand = brands.find(b => b.id === selected);
                setEditBrand(existingBrand);
              }}
              search={false}
            />
            <label>Voeg tekst toe:</label>
            <textarea
              className="editor"
              placeholder="Merk beschrijving"
              required={true}
              value={
                editBrand?.description ||
                "{name}-banden zijn de beste keuze voor bestuurders die het maximale uit hun auto willen halen. Ze bieden een reeks bandenmaten en loopvlakken, zodat u de perfecte pasvorm voor uw voertuig kunt vinden. De {name}-band is een hoogwaardig product met een lage prijs. Het is gemaakt van de beste materialen en is getest om ervoor te zorgen dat het veilig is voor de bestuurder en zijn voertuig.\n\n\n{name}-banden staan bekend om hun eersteklas kwaliteit, met een efficiëntie die overeenkomt met of beter is dan die van andere toonaangevende merken in de industrie. Ze staan ook bekend als betaalbaar, met prijzen die vergelijkbaar zijn met die van andere merken, waardoor ze een populaire keuze zijn onder chauffeurs.\n\n\nChauffeurs die op zoek zijn naar een nieuwe set banden, moeten {name} overwegen.\n"
              }
              onChange={e => {
                if (editBrand) {
                  setEditBrand({
                    ...editBrand,
                    description: e.target.value,
                  });
                }
              }}
            />
            {settingsSavedMessage ? (
              <div className={`${settingsSavedMessage.success ? "" : "red"}`}>
                {settingsSavedMessage.message}
              </div>
            ) : null}
            <button className="button mt-4" type="submit">
              Opslaan
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
